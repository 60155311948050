import {GenericItem, Item} from "../../types";

export const PartEditorButtons = (lastView: string, items: GenericItem[], hasFuture: boolean, hasPast: boolean) =>
	[
		{
			type: 'button',
			icon: 'top-view',
			action: 'y',
			active: lastView === 'y',
		},
		{
			type: 'button',
			icon: 'front-view',
			action: 'z',
			active: lastView === 'z',
		},
		{
			type: 'button',
			icon: 'side-view',
			action: 'x',
			active: lastView === 'x',
		},
		{
			type: 'divider',
		},
		{
			type: 'button',
			icon: 'rotate-anti-clockwise',
			action: 'ROTATE_ANTI_CLOCKWISE',
			disabled: !items.length,
		},
		{
			type: 'button',
			icon: 'rotate-clockwise',
			action: 'ROTATE_CLOCKWISE',
			disabled: !items.length,
		},
		{
			type: 'divider',
		},
		{
			type: 'button',
			icon: 'undo',
			action: 'UNDO',
			disabled: !hasPast,
		},
		{
			type: 'button',
			icon: 'redo',
			action: 'REDO',
			disabled: !hasFuture,
		},
		{
			type: 'divider',
		},
		{
			type: 'button',
			icon: 'delete',
			action: 'DELETE',
			disabled: !items.length,
		},
	];
