import {Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
	catchError,
	filter,
	Observable,
	Subject,
	switchMap,
	take,
	takeUntil,
	tap,
	throwError,
	combineLatest,
	debounceTime, map, distinctUntilChanged
} from 'rxjs';

import { aggregateDimensions } from '~modules/projects/helpers/aggregateDimensions';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { Item } from '~shared/types';
import {InputToEvalPipe} from "~shared/pipes/inputToEval.pipe";

@Component({
	selector: 'app-info-controller',
	templateUrl: './info-controller.component.html'
})
export class InfoControllerComponent implements OnInit, OnDestroy {
	public infoForm: FormGroup<{
		H: FormControl<string>;
		W: FormControl<string>;
		D: FormControl<string>;
	}>;
	public disableFields$: Observable<boolean>;
	public activeItem$: Observable<Item>;
	public updateRequired$: Observable<boolean>;

	private componentDestroyed$: Subject<boolean> = new Subject();
	private debounceTimer: any;

	constructor(
		private readonly fb: FormBuilder,
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
		private readonly inputToEval: InputToEvalPipe
	) {}

	@HostListener('document:keydown.escape', ['$event'])
	public onKeydownHandler(event: KeyboardEvent) {
		this.initializeDefaults();
	}

	ngOnInit(): void {
		this.infoForm = this.fb.group({
			H: [null],
			W: [null],
			D: [null]
		});

		this.activeItem$ = this.itemsRepository.activeItem$;
		this.updateRequired$ = this.editorRepository.updateRequired$;
		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => disabled ? this.infoForm.disable() : this.infoForm.enable());


		this.initializeDefaults();
		this.infoForm.valueChanges
			.pipe(
				debounceTime(600),
				distinctUntilChanged(),
				map(values => this.inputToEval.transform(values))
			)
			.subscribe(evaluatedValues => {
				this.infoForm.patchValue(evaluatedValues, { emitEvent: false });
				this.handleFormChanges(evaluatedValues);
			});
	}

	private handleFormChanges(formValues): void {
		this.editorRepository.setDimensions(formValues);
		this.editorRepository.updateRequired$.next(true);
	}

	public initializeDefaults(): void {
		this.itemsRepository.activeItem$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((item) => !!item)
			)
			.subscribe(({ outline: { dimension } }) => {
				this.infoForm.reset();
				this.editorRepository.setDimensions({
					H: dimension.height['H'].value.toString(),
					W: dimension.width['W'].value.toString(),
					D: dimension.depth['D'].value.toString(),
				})
				this.infoForm.patchValue({
					H: dimension.height['H'].value.toString(),
					W: dimension.width['W'].value.toString(),
					D: dimension.depth['D'].value.toString(),
				}, {
					emitEvent: false
				})
			})
	}

	public submitForm(): void {
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.dimensions$])
			.pipe(
				take(1),
				debounceTime(1000),
				tap(() => this.editorRepository.setLoading(true)),
				switchMap(([item, dimensions]) => {
					return this.itemsRepository.updateItemDimension(item.id, item.partId, aggregateDimensions({ dimensions }, item).dimension)
						.pipe(take(1))
				}),
				catchError((err) => {
					this.initializeDefaults()
					this.editorRepository.setLoading(false)
					return throwError(() => err);
				})
			)
			.subscribe(() => this.editorRepository.setLoading(false))
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public keyEnterHandler(): void {
		if (this.infoForm.valid) {

			clearTimeout(this.debounceTimer);
			this.debounceTimer = setTimeout(() => {
				this.editorRepository.triggerCabinetCreation$.next(true);
			}, 1000);
		}
	}
}
