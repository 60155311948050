import {
	ArticleType,
	BoardColour,
	Colour, FrontConnection,
	HardwareColour,
	HardwareHandleType,
	HardwareType,
	PanelType
} from "~shared/enums";

import { ConnectionType, ItemType } from "../enums/item.enum";

import { Dimension, TopDimensions, SideDimensions, FrontDimensions, ICoordinates } from "./dimension.types";
import { IArticle, IArticleHardware } from "./article.types";
import { IArticleZone } from "./article-zone.types";
import {Shape, ShapeParameters} from "./shape.types";
import {ICutout} from "~shared/types/cutout.types";
import {IConnection} from "~shared/types/connection.types";
import {IParameterSet} from "~shared/types/parameter.types";

export interface ItemMaterialMap {
	dimension: {
		length: number;
		width: number;
		thickness: number;
	};
	colour: BoardColour;
	sku: string;
}

export interface ItemHandleConnector {
	sku: string;
	catalogItem: {
		handleType: HardwareHandleType;
		hardwareType: HardwareType;
		id: string;
		label: string;
		locations: string[];
	}
	variant: {
		colour: HardwareColour;
		sku: string;
	}
}

export interface ItemHandle {
	id: string;
	connectionType: string;
	hardware: IArticleHardware[];
	location: string;
	locations: string[];
	objPositions: {
		offset: ICoordinates;
		position: ICoordinates;
		rotation: ICoordinates;
	}[]
}

export interface ItemDimension {
	height: { [key: string]: Dimension };
	depth: { [key: string]: Dimension };
	width: { [key: string]: Dimension };
	topDimension?: TopDimensions;
	sideDimension?: SideDimensions;
	frontDimension?: FrontDimensions;
}

export interface ItemPartCoordinate {
	partCoordinate: {
		x: number;
		y: number;
		z: number;
	};
	partRotation: {
		x: number;
		y: number;
		z: number;
	};
}

export interface ItemOutline {
	baseIdx: number;
	dimension: ItemDimension;
	fillerIdxs: number[];
	frontOutline?: string;
	sideOutline?: string;
	topOutline?: string;
}

export interface ItemConfigurationOptions {
	connectionType: ConnectionType;
	maximumEdgebanding: boolean;
	visibleConnections: boolean;
	connectionTypeOptions: string[];
}

export interface FillerZone {
	id: string;
	shape: Shape;
	article: IArticle;
	outlineFaceIdx: number;
	outlineFaceNormal: string;
}

export interface Item {
	id: string;
	itemType: ItemType;
	name: string;
	articleZones: IArticleZone[];
	fillerZones: FillerZone[];
	articles: IArticle[];
	outline: ItemOutline;
	configurationOptions: ItemConfigurationOptions;
	customisations: unknown[];
	fillerArticles: IArticle[];
	frontArticle?: IArticle;
	materialMap: Record<string, string[]>;
	partId: string;
	partCoordinate?: {
		x: number;
		y: number;
		z: number;
	};
	partRotation?: {
		x: number;
		y: number;
		z: number;
	};
}

export interface GenericItem {
	panelType?: PanelType;
	backPanelType?: string;
	edgeband?: boolean;
	faceNormal?: string;
	faceIndex?: number;
	disMountable?: boolean;
	panelGroup?: ArticleType;
	id: string;
	connectorType?: string;
	colour?: Colour;
	articleZone?: IArticleZone;
	article?: IArticle;
	outlineFaceIdx?: number;
	frontConnection?: FrontConnection;
	cutouts?: ICutout[];
	construction?: {
		topConstructionType?: string;
		topConstructionTypeOptions?: string[];
		bottomConstructionType?: string;
		bottomConstructionTypeOptions?: string[];
		leftConstructionType?: string;
		leftConstructionTypeOptions?: string[];
		rightConstructionType?: string;
		rightConstructionTypeOptions?: string[];
	}
	hingeConnection?: IConnection;
	handleConnection?: any;
	removable?: boolean;
	articleZoneBackFace?: boolean;
	faceIdx?: number;
	customisations?: string[];
	parameterSet?: IParameterSet;
	board?: {
		sku: string;
	};
	type?: string;
	location?: string;
	shape?: Partial<ShapeParameters>
}

