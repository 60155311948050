import { Component, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import {GenericItem, Item} from '~shared/types';


@Component({
	templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
	public activePanel$: Observable<GenericItem>;
	public activeItem$: Observable<Item>;

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
	) {}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.activePanel$ = this.editorRepository.selectedItems$
			.pipe(map(([item]) => item));
	}
}
