import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { omit } from 'ramda';
import { catchError, combineLatest, filter, map, Observable, skip, startWith, Subject, switchMap, take, takeUntil, throwError } from 'rxjs';

import { ArticleType, BoundaryMode, DivisionMode, PlaneAxis } from '~shared/enums/item.enum';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { ExpressionValidators } from '~shared/validators/expression';
import { CustomValidators } from '~shared/validators/custom';
import {GenericItem, Item} from '~shared/types';

@Component({
	templateUrl: './dynamic-divider.component.html',
})
export class DynamicDividerComponent implements OnInit, OnDestroy {
	public configurationForm: FormGroup<{
		id: FormControl<string>;
		expression: FormControl<string>;
		adjustmentLevels: FormControl<number>;
		offset_front: FormControl<string>;
		offset_back: FormControl<string>;
		divisionMode: FormControl<DivisionMode>;
		lineBoring: FormControl<boolean>
	}>;
	public includeDividerDisabled = false;
	public openItems: Record<string, boolean> = {
		GENERAL: true,
		OFFSET: true
	};

	private componentDestroyed$: Subject<boolean> = new Subject()
	public activePanel$: Observable<GenericItem>;
	public disableField$: Observable<boolean>;
	public activeItem$: Observable<Item>;
	public adjustmentLevelLoading = null;

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
		private readonly fb: FormBuilder
	) {}

	@HostListener('document:keydown.escape', ['$event'])
	public onKeydownHandler(event: KeyboardEvent) {
		this.initializeDefaults();
	}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.activePanel$ = this.editorRepository.selectedItems$
			.pipe(map(([item]) => item));

		this.configurationForm = this.fb.group({
			id: ['new'],
			expression: ['', [Validators.required, ExpressionValidators.checkExpression()]],
			adjustmentLevels: [1, [Validators.required, CustomValidators.oneOf([1, 3, 5, 7])]],
			offset_back: ['0', [Validators.required, Validators.min(0)]],
			offset_front: ['0', [Validators.required, Validators.min(0)]],
			divisionMode: ['INCLUDE_DIVIDER' as DivisionMode],
			lineBoring: [false]
		});

		this.configurationForm.get('expression').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((expression) => {
				if (new RegExp(/(\:0(mm)?\:)|(^0(mm)?\:)/).test(expression)) {
					this.includeDividerDisabled = true;
				} else {
					this.includeDividerDisabled = false;
				}
			})

		this.disableField$ = this.editorRepository.disableFields$;
		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => disabled
				? this.configurationForm.disable({ emitEvent: false })
				: this.configurationForm.enable({ emitEvent: false }))

		combineLatest([
			this.configurationForm.get('lineBoring').valueChanges.pipe(startWith(null)),
			this.configurationForm.get('adjustmentLevels').valueChanges.pipe(startWith(null)),
			this.configurationForm.get('divisionMode').valueChanges.pipe(startWith(null)),
		])
			.pipe(
				skip(1),
				takeUntil(this.componentDestroyed$)
			)
			.subscribe(() => setTimeout(() => this.submitForm()))

		this.initializeDefaults();
	}

	public populateExpression(expression: string): void {
		this.configurationForm.patchValue({
			expression
		}, {
			emitEvent: false
		});
		this.configurationForm.markAsDirty();
		this.submitForm();
	}

	public populateAdjustmentLevels(adjustmentLevels: number): void {
		this.adjustmentLevelLoading = adjustmentLevels;
		this.configurationForm.patchValue({
			adjustmentLevels
		}, {
			emitEvent: false
		});
		this.configurationForm.markAsDirty();
		this.submitForm();
	}

	public submitForm(): void {
		const { value } = this.configurationForm;

		if (value.id === 'new') {
			return this.createItem();
		}

		this.editorRepository.setLoading(true);
		this.itemsRepository.activeItem$
			.pipe(
				take(1),
				switchMap((item) => this.itemsRepository.updateShelf(item.partId, item.id, value.id, {
					linearDivision: {
						divisionMode: value.divisionMode,
						expression: value.expression
					},
					lineBoring: value.lineBoring,
					...(value.lineBoring !== true && { adjustmentLevels: value.adjustmentLevels }),
				}).pipe(take(1))),
				catchError((err) => {
					this.adjustmentLevelLoading = null;
					this.editorRepository.setLoading(false)
					return throwError(() => err);
				}),
				switchMap((updatedArticle) => this.editorRepository.selectedItems$.pipe(take(1), map((selectedItems) => ({ selectedItems, updatedArticle })))),
			)
			.subscribe(({ updatedArticle, selectedItems }) => {
				this.adjustmentLevelLoading = null;
				this.editorRepository.setLoading(false);
				this.editorRepository.setSelectedItems(selectedItems.map((item) => ({ ...item, article: updatedArticle })));
			})
	}

	public createItem(): void {
		this.configurationForm.markAllAsTouched();
		const { value } = this.configurationForm;

		if (!this.configurationForm.valid) {
			return;
		}

		this.editorRepository.setLoading(true);
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.selectedArticleZones$])
			.pipe(
				take(1),
				switchMap(([item, [articleZone]]) => this.itemsRepository.createShelf(item.partId, item.id, articleZone?.id || item.articleZones[0].id, {
					linearDivision: {
						divisionMode: value.divisionMode,
						expression: value.expression
					},
					lineBoring: value.lineBoring,
					...(value.lineBoring !== true && { adjustmentLevels: value.adjustmentLevels }),
				})),
				catchError((err) => {
					this.editorRepository.setLoading(false)
					return throwError(() => err);
				})
			)
			.subscribe(([article]) => {
				this.editorRepository.setLoading(false);
				this.editorRepository.setSelectedItems([{ ...article.panels[0], article }]);
			})
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public initializeDefaults(): void {
		this.editorRepository.selectedArticles$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((articles) => !!articles && !!articles.length)
			)
			.subscribe(([article]) => {
				this.configurationForm.reset({}, { emitEvent: false });
				this.configurationForm.patchValue({
					id: article.id,
					expression: article.linearDivision.expression,
					lineBoring: article.lineBoring,
					adjustmentLevels: article.adjustmentLevels,
					divisionMode: article.linearDivision.divisionMode,
				}, { emitEvent: false })
			})
	}
}
