import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {CameraManager} from "./cameraManager";
import {SceneManager} from "./sceneManager";


export class ControlsManager {
    public controls?: OrbitControls;
    public enablePan: boolean;

    constructor(private readonly cameraManager: CameraManager,
                private readonly sceneManager: SceneManager) {
		this.controls = new OrbitControls(
			this.cameraManager.camera,
			this.sceneManager.renderer.domElement
		);
		this.controls.enablePan = this.enablePan;
		this.startControls();
    }


    initControls() {

    }

    startControls() {
        if (!this.controls) return console.error('Controls not defined');
        this.controls.addEventListener('change', () => {
            this.sceneManager.needToRender();
        });
    }

    disposeControls() {
        delete this.controls;
    }

    disableControls() {
        //Orbit controls doesn't allow to animate camera rotation, we need to disable it to fix
        this.controls.enabled = false;
        this.controls.reset();
    }
}
