import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {  combineLatest, filter, map, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

import { AuthService } from '~core/services/auth.service';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { HardwareItemsRepository } from '~modules/projects/store/hardware-items/hardware-items.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { HardwareType } from '~shared/enums';
import {GenericItem, HardwareItem, Item} from '~shared/types';

@Component({
	selector: 'app-handle-offset',
	templateUrl: './handle-offset.component.html'
})

export class HandleOffsetComponent implements OnInit, OnDestroy {
	@Input() partId: string;
	@Input() disabled: boolean = false;

	public hardwareItems$: Observable<HardwareItem[]>;
	public disableFields$: Observable<boolean>;
	public handleOffsetControl = new FormGroup({
		x: new FormControl(),
		y: new FormControl(),
		z: new FormControl(),
	});
	public selectedItem$: Observable<GenericItem>
	public isOpen = true;
	public loading = false;

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private editorRepository: EditorRepository,
		private authService: AuthService,
		private hardwareItemsRepository: HardwareItemsRepository,
		private itemsRepository: ItemsRepository,
	) { }

	public ngOnInit(): void {
		this.hardwareItems$ = this.hardwareItemsRepository.hardwareItems$
			.pipe(map((items) => items.filter((item) => item.isFavourite)));

		this.authService.currentUser$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((user) => !!user),
				switchMap((user) => this.hardwareItemsRepository.getHardwareItems(HardwareType.HANDLE, user.account.id)),
				take(1),
			)
			.subscribe();

		this.selectedItem$ = this.editorRepository.selectedItems$
			.pipe(
				map(([item]) => item)
			);

		this.editorRepository.selectedItems$
			.pipe(
				takeUntil(this.componentDestroyed$),
				tap(() => this.initializeDefaults())
			)
			.subscribe()

		this.disableFields$ = this.editorRepository.disableFields$
	}

	public initializeDefaults(): void {
		this.editorRepository.selectedItems$
			.pipe(
				take(1),
				switchMap(([item]) => this.hardwareItems$.pipe(
					map((hardwareItems) => ({
						hardwareItems,
						item
					}))
				)),
				tap(({ item, hardwareItems }) => {
					if (!item?.handleConnection) {
						return this.handleOffsetControl.setValue({
							x: 0,
							y: 0,
							z: 0,
						}, { emitEvent: false });
					}

					const hardwareItem = hardwareItems.find((hardwareItem) => hardwareItem.label === item.handleConnection.hardware[0]?.catalogItem?.label);
					return this.handleOffsetControl.setValue({
						x: hardwareItem?.obj?.offset?.x || 0,
						y: hardwareItem?.obj?.offset?.y || 0,
						z: hardwareItem?.obj?.offset?.z || 0,
					}, { emitEvent: false });
				})
			)
			.subscribe()
	}

	public toggleItem(): void {
		this.isOpen = !this.isOpen
	}

	public submitForm(): void {
		combineLatest([
			this.itemsRepository.activeItem$,
			this.selectedItem$,
		])
			.pipe(
				take(1),
				switchMap(([item, door]: [Item, GenericItem]) => {
					return this.itemsRepository.updatePanelConnection(item.partId, item.id, door.article?.id, door.id, 'HANDLE', {
						offset: this.handleOffsetControl.value,
					}).pipe(take(1))
				})
			)
			.subscribe()
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
