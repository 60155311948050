import {bufferMaterialMap, customisedMaterialMap} from "~shared/components/cabinet-builder/utils";
import {GenericItem} from "~shared/types";

export const getDefaultMaterial = (item: any, transparentPanelTypes: string[], isolatedItem: GenericItem) => {
	const hasCustomisation = !!(
		item?.userData?.object?.customisations || []
	).length;
	const shouldBeTransparent = (transparentPanelTypes || [])?.includes(
		item?.userData?.object?.panelType
	);
	const hasEdgeband = item?.userData?.object?.edgeband;
	const isArticleZoneBackFace = item?.userData?.object?.articleZoneBackFace;
	const shouldBeIsolated =
		isolatedItem?.id &&
		isolatedItem.id !== item?.userData?.object?.id;

	if (item.material) {
		const colour =
			item?.userData?.object?.colour || '#FFFFFF';
		const sharedParameters = {
			colour,
			...(isArticleZoneBackFace && {
				colour: 0xff00ff,
			}),
			...(hasEdgeband && {
				colour: 0xffff00,
			}),
			...(shouldBeTransparent && {
				opacity: 0.5,
				transparent: true,
			}),
			...(shouldBeIsolated && {
				opacity: 0.15,
				transparent: true,
			}),
		}

		if (hasCustomisation) {
			return customisedMaterialMap({
				...(item?.userData?.object?.mesh || {}),
				...sharedParameters,
			});
		}

		return bufferMaterialMap({
			...item?.userData?.object?.mesh,
			... sharedParameters
		});
	}
}
