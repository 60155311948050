import {Mesh} from "three";
import {PanelType} from "../../../enums";
import {SceneManager} from "./sceneManager";

export class ConfigurationManager {

	constructor(private readonly sceneManager: SceneManager) {
	}

	triggerDoors(doorIds: string[], openAllDoors: boolean = false, lastOpenDoors: string[]): string[] {
		const allDoors: any = this.findDoors(this.sceneManager.scene.children);
		if (openAllDoors) {
			return allDoors.forEach((door) => {
				door.userData.disallowSelection = true;
				door.visible = false;
				this.sceneManager.needToRender(50);
			})
		}

		allDoors.forEach((door) => {
			const shouldBeOpen = doorIds.includes(door.userData.object.id);
			const wasOpen = lastOpenDoors.includes(door.userData.object.id);

			// If the door should be open but it was open, ignore
			if (shouldBeOpen === wasOpen) {
				return;
			}

			// If the door should be open but it was closed, open it
			if (shouldBeOpen && !wasOpen) {
				door.visible = false;
				// const transparentMaterial = objectDefaultMaterial.clone();
				// transparentMaterial.opacity = 0;
				// transparentMaterial.transparent = true;
				// Utils.applyTemporaryMaterial([door, ...(door.children as Mesh[])], transparentMaterial);
				this.sceneManager.needToRender(50);
				return;
			}

			// If the door should be closed but it was open, close it
			if (!shouldBeOpen && wasOpen) {
				door.visible = true;
				// Utils.restoreDefaultMaterial([door, ...(door.children as Mesh[])]);
				this.sceneManager.needToRender(50);
				return;
			}
		});

		return doorIds;
	}

	private findDoors(children: any[]): Mesh[] {
		return children.reduce((acc, group) => {
			const childrenDoors = this.findDoors(group.children || []);

			if ([PanelType.FRONT, PanelType.DRAWER_FRONT].includes(group?.userData?.object?.panelType)) {
				return [...acc, ...childrenDoors, group];
			}

			return [...acc, ...childrenDoors]
		}, []);
	}
}
