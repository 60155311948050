import {TweenManager} from "./tweenManager";
import {PerspectiveCamera, Vector3} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

const fov = 20;
export const cameraStartPos = { x: -5, y: 3, z: 7 };


export class CameraManager {
    public camera?: PerspectiveCamera;
    cameraDistance = 24.5;
    public defaultCameraTopRotation = new Vector3(-Math.PI/2, -Math.PI/2, 0);
    public controls?: OrbitControls;

   constructor() {
   }

   initializeCamera(container: HTMLElement, cameraStartParams = cameraStartPos) {
       const bounding = container.getBoundingClientRect();
       const { width, height } = bounding;
       this.camera = new PerspectiveCamera(fov, width / 689, 0.1, 1000);
       this.camera.position.set(
           cameraStartParams.x,
           cameraStartParams.y,
           cameraStartParams.z
       );
       if (!this.camera ) {
           console.error(`Unable to initialize scene: Missing camera.`);
           return;
       }

       this.camera.aspect = width / 689;
       this.camera.updateProjectionMatrix();
   }


    updateCamera(width: number, height: number) {
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
    }

    disposeCamera() {
       delete this.camera;
    }

}
