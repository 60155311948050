import {ThreeObjectTypeEnum} from "~shared/enums";
import {GenericItem} from "~shared/types/item.types";

export interface Shape {
	coordinates: number[]
}


export class ShapeParameters {
	width: number;
	height?: number;
	depth?: number;
	length?: number;
	thickness?: number;
}

export class ShapeInfo {
	type: ThreeObjectTypeEnum;
	object: GenericItem;
}

