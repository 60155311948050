import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { combineLatest, map, mergeMap, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import {GenericItem, Item} from '~shared/types';

@Component({
	templateUrl: './face.component.html',
})
export class FaceComponent implements OnInit, OnDestroy {
	public openItems: Record<string, boolean> = {
		GENERAL: true,
	};
	public selectedItem$: Observable<GenericItem>;
	public activePanel$: Observable<GenericItem>;
	public activeItem$: Observable<Item>;
	public edgeControl = new FormControl();

	private componentDestroyed$: Subject<boolean> = new Subject()

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
	) {}

	public ngOnInit(): void {
		this.activePanel$ = this.editorRepository.selectedItems$
			.pipe(map(([item]) => item));
		this.editorRepository.selectedItems$
			.pipe(
				takeUntil(this.componentDestroyed$)
			)
			.subscribe(([item]) => this.edgeControl.patchValue(item?.edgeband || false, { emitEvent: false }));

		this.edgeControl.valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$),
				mergeMap((values) => {
					return combineLatest([this.activePanel$, this.itemsRepository.activeItem$, this.editorRepository.selectedItems$]).pipe(
						take(1),
						map(([panel, item, [selectedItem]]) => {
							return { values, panel, item, selectedItem };
						}),
					);
				}),
				switchMap(({ values, item, panel, selectedItem }) => {
					if (!item || !panel) {
						return
					}

					return this.itemsRepository.updatePanel(item.partId, item.id, panel.id, {
						edges: [{
							faceNormal: selectedItem?.faceNormal,
							faceIndex: selectedItem?.faceIndex,
							edgeband: values,
						}]
					})
				}),
				take(1)
			)
			.subscribe()
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
