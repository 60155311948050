import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDialogModule } from '@angular/material/dialog';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgxEditorModule } from 'ngx-editor';
import { A11yModule } from '@angular/cdk/a11y'
import { RouterModule } from '@angular/router';

import { Directives } from './directives';
import { Components } from './components';
import { Repositories } from './store';
import { Pipes } from './pipes';
import { StandardDialogComponent } from './components/standard-dialog/standard-dialog.component';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import {InputToEvalPipe} from "~shared/pipes/inputToEval.pipe";

@NgModule({
	declarations: [
		InputDialogComponent,
		StandardDialogComponent,
		Components,
		Directives,
		Pipes,
	],
	providers: [Repositories, InputToEvalPipe],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		NgScrollbarModule,
		NgxPopperjsModule,
		A11yModule,
		NgxEditorModule,
		MatDialogModule,
		RouterModule,
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		Components,
		Directives,
		Pipes,
		NgScrollbarModule,
		NgxPopperjsModule,
		A11yModule,
		NgxEditorModule,
		MatDialogModule,
		RouterModule,
	],
})
export class SharedModule {}
