import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { omit } from 'ramda';

import {GenericItem, ParameterWithLocation} from '~shared/types';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ParameterValue } from '~shared/shared.types';
import { ParameterValidators } from '~shared/validators/parameters';
import { findUpdatedPanel } from '~shared/helpers';
import { CustomValidators } from '~shared/validators/custom';

import { reverseEdgeLocations } from './panel-cutout.const';

@Component({
	selector: 'app-panel-cutout',
	templateUrl: './panel-cutout.component.html',
})
export class PanelCutoutComponent implements OnInit, OnDestroy {
	public cutoutTypeCreateLoading: string;
	public cutoutIdUpdateLoading: string;
	public cutoutIdDeleteLoading: string;
	public isOpen = true;
	public show = false;
	public selectedPanel$: Observable<GenericItem>;
	public form: FormGroup[] = [];

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
		private readonly fb: FormBuilder
	) {}

	public ngOnInit(): void {
		this.selectedPanel$	 = this.editorRepository.selectedItems$
			.pipe(
				map(([panel]) => panel),
				filter((panel) => !!panel),
				tap((panel) => this.show = Array.isArray(panel.cutouts)),
			);

		this.form = [];

		this.selectedPanel$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((panel) => !!panel))
			.subscribe((panel) => {
				this.form = [];
				(panel.cutouts || []).forEach((cutout) => {
					const startFormGroup = {
						...(cutout.hardware && { 'hardwareColour': [cutout.hardware.colour] })
					}

					const formGroup = this.fb.group(Object.keys(cutout.position).reduce((acc, key) => {
						const cutoutPosition = cutout.position[key];

						if ((cutoutPosition as ParameterWithLocation).edgeLocation) {
							const { edgeLocation, dimension } = cutoutPosition as ParameterWithLocation;
							return {
								...acc,
								[key]: this.fb.group({
									dimension: this.fb.group({
										value: [dimension?.value || dimension.defaultValue || 0, [CustomValidators.numeric, ParameterValidators.checkConstraints(dimension.constraint)]],
									}),
									edgeLocation: [edgeLocation]
								})
							}
						}

						const dimension = cutoutPosition as ParameterValue;
						return {
							...acc,
							[key]: this.fb.group({
								value: [dimension.value || dimension.defaultValue || 0, [CustomValidators.numeric, ParameterValidators.checkConstraints(dimension.constraint)]],
							})
						};
					}, startFormGroup));

					this.form.push(formGroup);
				})
			})
	}

	public reverseEdgeLocation(cutoutId: string, formIndex: number, type: string): void {
		const currentEdgeLocation = this.form[formIndex].get(type).get('edgeLocation')?.value;

		this.form[formIndex].get(type).get('edgeLocation').setValue(reverseEdgeLocations[currentEdgeLocation]);
		setTimeout(() => this.saveCutout(cutoutId, formIndex, this.form[formIndex].value));
	}

	public setDiameter(cutoutId: string, formIndex: number, diameter: number): void {
		this.form[formIndex].get('diameter').get('value').setValue(diameter);
		setTimeout(() => this.saveCutout(cutoutId, formIndex, this.form[formIndex].value));
	}

	public setHardwareColour(cutoutId: string, hardwareId: string, formIndex: number, colour: string): void {
		this.form[formIndex].get('hardwareColour').setValue(colour);
		this.cutoutIdUpdateLoading = cutoutId;

		combineLatest([this.editorRepository.selectedItems$, this.itemsRepository.activeItem$])
			.pipe(
				take(1),
				switchMap(([[panel], item]) => this.itemsRepository.updateHardware(item.partId, item.id, hardwareId, { colour })),
				take(1)
			)
			.subscribe(() => {
				this.cutoutIdUpdateLoading = null;
			})
	}

	public toggleItem(): void {
		this.isOpen = !this.isOpen
	}

	public saveCutout(cutoutId: string, formIndex: number, formValue): void {
		this.cutoutIdUpdateLoading = cutoutId;
		combineLatest([this.editorRepository.selectedItems$, this.itemsRepository.activeItem$])
			.pipe(
				take(1),
				switchMap(([[panel], item]) => this.itemsRepository.updatePanelCutout(item.partId, item.id, panel.article?.id, panel.id, cutoutId, omit(['hardwareColour'])(formValue))),
				take(1)
			)
			.subscribe(() => {
				this.cutoutIdUpdateLoading = null;
				this.form[formIndex].markAsPristine();
			})
	}

	public createCutout(e: Event, type: string): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.cutoutTypeCreateLoading = type;

		combineLatest([this.editorRepository.selectedItems$, this.itemsRepository.activeItem$])
			.pipe(
				take(1),
				switchMap(([[panel], item]) => this.itemsRepository.createPanelCutout(item.partId, item.id, panel.article?.id, panel.id, type)),
				switchMap((item) => this.selectedPanel$.pipe(take(1), map((panel) => ({ panel, item }))))
			)
			.subscribe(({ panel, item }) => {
				this.cutoutTypeCreateLoading = null;
				this.editorRepository.setSelectedItems([findUpdatedPanel(item, panel)])
			})
	}

	public deleteCutout(cutoutId: string): void {
		this.cutoutIdDeleteLoading = cutoutId;

		combineLatest([this.editorRepository.selectedItems$, this.itemsRepository.activeItem$])
			.pipe(
				take(1),
				switchMap(([[panel], item]) => this.itemsRepository.deletePanelCutout(item.partId, item.id, panel.article?.id, panel.id, cutoutId)),
				switchMap((item) => this.selectedPanel$.pipe(take(1), map((panel) => ({ panel, item }))))
			)
			.subscribe(({ panel, item }) => {
				this.cutoutIdDeleteLoading = null;
				this.editorRepository.setSelectedItems([findUpdatedPanel(item, panel)])
			})
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
