import { createStore, withProps } from '@ngneat/elf';

import { DesignerMode } from '~shared/enums';

import { EditorMode, EditorState } from './editor.types';

export const editorStore = createStore(
	{ name: 'editor' },
	withProps<EditorState>({
		activeConfigurationPanel: null,
		designerMode: DesignerMode.PROJECT,
		selectedItems: [],
		outlineMap: null,
		dimensions: null,
		selectedFaceIdx: 0,
		loading: false,
		locked: false,
		partEditorFullscreen: false,
		partEditorSelectedItem: null,
		fillerHighlight: null,
		articles: [],
		transparentPanelTypes: [],
		openDoorIds: [],
		parameters: [],
		isolatedItem: null,
		mode: EditorMode.DEFAULT,
		container: null
	})
)
