import { Component, Input, Type, ViewChild, OnInit, EventEmitter, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';

import { Item } from '~shared/types';

import { ConfigurationControllerDirective } from '../../directives/configuration-controller/configuration-controller.directive';

@Component({
	selector: 'app-editor-configuration',
	templateUrl: './editor-configuration.component.html'
})

export class EditorConfigurationComponent implements OnInit, OnChanges {
	@Input() activeConfigurationPanel: string;
	@Input() configurationControllers: ConfigurationItem[];
	@Input() public item: Item;
	@Input() defaultConfigurationController: ConfigurationItem;

	@ViewChild(ConfigurationControllerDirective, { static: true }) ribbonControllerHost!: ConfigurationControllerDirective;

	public ngOnInit(): void {
		this.loadComponent();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.loadComponent();
	}

	loadComponent(): void {
		const viewContainerRef = this.ribbonControllerHost.viewContainerRef;
		viewContainerRef.clear();

		if (this.activeConfigurationPanel === null && !!this.item && this.item.id !== 'new') {
			viewContainerRef.createComponent(this.defaultConfigurationController.component);
			return
		}

		const controller = this.configurationControllers.find((controller) => controller.name === this.activeConfigurationPanel)

		if (!controller) {
			return
		}

		viewContainerRef.createComponent(controller.component);
	}
}

export class ConfigurationItem {
	constructor(public component: Type<any>, public name: string) {}
}
