import { Subject, take, tap, timer } from 'rxjs';
import { Box3, BufferGeometry, Group, Mesh, Vector2, Vector3 } from 'three';

import { DesignerMode, PanelType, SceneTypeEnum } from '~shared/enums';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { EditorMode } from '~modules/projects/store/editor/editor.types';
import { PartPosition } from '~shared/shared.types';
import { ConfigurationManager } from '~shared/components/cabinet-builder/managers/configurationManager';
import {
	CameraManager,
	cameraStartPos,
} from '~shared/components/cabinet-builder/managers/cameraManager';
import { SceneManager } from '~shared/components/cabinet-builder/managers/sceneManager';
import { ControlsManager } from '~shared/components/cabinet-builder/managers/controlsManager';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { TweenManager } from '~shared/components/cabinet-builder/managers/tweenManager';
import { CollisionManager } from '~shared/components/cabinet-builder/managers/collisionManager';
import { GenericItem, Item, ShapeInfo } from "~shared/types";

import {
	centerGroups,
	getSceneDimensions,
	mergeBufferGeometries,
} from './utils';
import { Loader } from './loader';
import { CutoutShapes } from './descoped/cutoutShapes';
import { MovementManager } from './managers/movementManager';
import { ObjectBuilder } from './objectBuilder';
import { Measurements } from './descoped/measurements';
import { SelectionManager } from './managers/selectionManager';

export interface IBuildItemProps {
	item: Item;
	transparentPanelTypes: PanelType[];
	isolatedItem: GenericItem;
	openDoorIds?: string[];
	hideDoors?: boolean;
	editorMode: EditorMode;
	renderView?: string;
}

export interface IBuildPartItemProps {
	item: Item;
	hideTimer: number;
	hideDoors: boolean;
	renderView: string;
	clearPositions?: boolean;
}

const cameraDistance = 24.5;

export class GeneralItemEditor {
	public groups: Group[] = [];
	public currentObjectId: string;
	public isClickAndHold = false;
	public lastOpenDoors: string[] = [];
	public lastSelectedObj: Mesh;
	public subscription;
	public loader: Loader = new Loader();
	public cutoutGenerator: CutoutShapes = new CutoutShapes();
	public movableObjects = [];
	public mouseVector: Vector2 = new Vector2();
	public movementManager = new MovementManager(this.mouseVector, this.sceneManager, this.cameraManager);
	public isPointerdown: boolean = false;
	public activeCutout?: Mesh;
	public transparentPanelTypes: PanelType[];
	public isolatedItem: GenericItem;
	public editorMode: EditorMode;

	private planeIntersect = new Vector3(); // point of intersection with the plane

	///
	public activeItem?: Group;
	public lockedAxis: 'x' | 'y' | 'z' = 'z';
	public collisionObjects: Array<Group> = [];
	public selectedGroup: Group | null;
	public minPositionValues = {
		x: -7.5,
		y: 0,
		z: -7.5,
	};
	public maxPositionValues = {
		x: 7.5,
		y: 7.5,
		z: 7.5,
	};
	public positions$: Subject<PartPosition[]> = new Subject();
	collisionManager!: any;

	constructor(
		private readonly objectBuilder: ObjectBuilder,
		private readonly measurementsManager: Measurements,
		private readonly selectionManager: SelectionManager,
		private readonly editorRepository: EditorRepository,
		private readonly cameraManager: CameraManager,
		private readonly sceneManager: SceneManager,
		private readonly controlsManager: ControlsManager,
		private readonly configurationManager: ConfigurationManager,
		private readonly itemsRepository: ItemsRepository,
		private readonly tweenManager: TweenManager,
	) {
		this.editorRepository.mode$
			.pipe(tap((mode) => this.editorMode = mode))
			.subscribe();
		this.collisionManager = new CollisionManager(
			this.minPositionValues,
			this.maxPositionValues
		);
	}

	build(itemProps: IBuildItemProps | IBuildPartItemProps ): void {
		if (this.sceneManager.sceneType === SceneTypeEnum.ITEM_SCENE && this.isTypeItem(itemProps)) {
			this.transparentPanelTypes = itemProps.transparentPanelTypes;
			this.isolatedItem = itemProps.isolatedItem;
			const cabinet = this.objectBuilder.buildCabinet(itemProps.item, itemProps.transparentPanelTypes,
				itemProps.isolatedItem, itemProps.editorMode);
			const [panelGroup] = cabinet;

			//// Panel groups
			this.sceneManager.scene.add(panelGroup as Group);
			this.groups.push(panelGroup as Group);

			//// Filler groups
			for (const panelGroup of cabinet[3] as Group[]) {
				this.sceneManager.scene.add(panelGroup);
				this.groups.push(panelGroup);
			}

			for (const baseGroup of cabinet[1] as Group[]) {
				this.sceneManager.scene.add(baseGroup);
				this.groups.push(baseGroup);
			}

			//// ArticleZone group[]
			this.sceneManager.scene.add(cabinet[2] as Group);
			this.groups.push(cabinet[2] as Group);

			if (this.currentObjectId !== itemProps.item.id) {
				this.currentObjectId = itemProps.item.id;
				const center = this.getObjectCenter(cabinet[2] as Group, false);

				if (itemProps.renderView === 'default' || !itemProps.renderView) {
					this.cameraManager.camera.position.set(cameraStartPos.x, cameraStartPos.y, cameraStartPos.z);
				} else if (itemProps.renderView === 'front') {
					this.cameraManager.camera.position.set(center.x, center.y, 8);
				}
			}

			this.getObjectCenter(panelGroup as Group);

			(cabinet[0] as Group).children.forEach((group) => {
				this.addDoorTweens(group as Group, itemProps.openDoorIds);
			})
			this.sceneManager.startRenderLoop();
			this.controlsManager.controls.update();

			if (itemProps.hideDoors) {
				this.triggerDoors(true, [], true)
			}

		} else if ((this.sceneManager.sceneType === SceneTypeEnum.PART_SCENE) && this.isTypePart(itemProps)) {
			if (itemProps.clearPositions) {
				this.positions$.next([]);
				this.collisionObjects = [];
			}
			const finalObject: Group = this.objectBuilder.buildPart(itemProps.item);

			const center = this.getObjectCenter(finalObject as Group);

			if (itemProps.renderView === 'front') {
				this.cameraManager.camera.position.set(center.x, center.y, 8);
			}

			this.sceneManager.scene.add(finalObject);
			this.collisionObjects.push(finalObject);
			this.sceneManager.startRenderLoop();
			this.controlsManager.controls.update();

			if (itemProps.hideDoors) {
				this.triggerDoors(true, [], true)
			}
			const positions = this.movementManager.saveItemPositions(this.groups);
			setTimeout(() => this.positions$.next(positions))
		}
		this.editorRepository.selectedItems$
			.pipe(take(1))
			.subscribe((items) => {
				this.highlightObjects(items, this.isolatedItem);
			});

	}

	getObjectCenter(finalObject: Group, getSizeStatus: boolean = true): Vector3 {
		const aabb = new Box3().setFromObject(finalObject as Group);
		const center = aabb.getCenter(new Vector3());
		this.controlsManager.controls.target = center;
		if (getSizeStatus) {
			const size = new Vector3();
			aabb.getSize(size);
		}

		return center;
	}

	disposeCurrentModel(): void {
		this.removeMeasurements();
		this.sceneManager.disposeCurrentGroups(this.groups);
	}

	// used for disposing of all measurements
	removeMeasurements() {
		if (!this.sceneManager.renderer) {
			return;
		}

		this.sceneManager.renderer.sortObjects = true;
		this.measurementsManager.dispose();
	}


	addDoorTweens(group: Group, openDoorIds: string[]) {
		for (let i = group.children.length - 1; i >= 0; i--) {
			const mesh = group.children[i];
			if (openDoorIds.includes(mesh.userData.object.id)) {
				mesh.userData.disallowSelection = true;
				mesh.visible = false;

				// const objectDefaultMaterial = mesh.userData.defaultMaterial;
				// const transparentMaterial = objectDefaultMaterial.clone();
				// transparentMaterial.opacity = 0.2;
				// transparentMaterial.transparent = true;
				// Utils.applyTemporaryMaterial([mesh as Mesh, ...(mesh.children as Mesh[])], transparentMaterial)
			}
		}
	}

	triggerDoors(open: boolean, doorIds: string[], openAllDoors: boolean = false) {
		this.lastOpenDoors = this.configurationManager.triggerDoors(doorIds, openAllDoors, this.lastOpenDoors);
	}

	startTimer() {
		const source = timer(300);
		this.subscription = source.subscribe(() => {
			this.isClickAndHold = true;
		});
	}

	endTimer() {
		this.subscription.unsubscribe();
	}

	selectObject(
		mouseX: number,
		mouseY: number,
		isCtrlDown: boolean
	): {
		primaryObjects: ShapeInfo[];
		allObjects: ShapeInfo[];
	} {


		const {primaryObjects, allObjects,isClickAndHold , lastSelectedObj } = this.selectionManager.selectObject(mouseX, mouseY, isCtrlDown, this.isClickAndHold,
			this.lastSelectedObj, this.transparentPanelTypes, this.isolatedItem, this.editorMode);
		this.isClickAndHold = isClickAndHold;
		this.lastSelectedObj = lastSelectedObj;

		return  {primaryObjects, allObjects};
	}

	highlightObjects(items: GenericItem[], isolatedItem: GenericItem): void {
		this.selectionManager.highlightObjects(items, isolatedItem, this.editorMode);
	}

	// add changes from partEditor
	addMovementEvents() {
		const movementFunctions = [
			{
				funct: this.sceneManager.sceneType === SceneTypeEnum.ITEM_SCENE ? this.pointerdown : this.selectObject3D,
				eventName: 'pointerdown',
				editor: this,
			},
			{
				funct: this.sceneManager.sceneType === SceneTypeEnum.ITEM_SCENE ? this.pointerup : this.deselectObject3D,
				eventName: 'pointerup',
				editor: this,
			},
			{
				funct: this.dragObject,
				eventName: 'pointermove',
				editor: this,
			},

		];
		if (this.sceneManager.sceneType === SceneTypeEnum.PART_SCENE) {
			movementFunctions.push(
				{
					funct: this.doubleClickObject3D,
					eventName: 'dblclick',
					editor: this,
				},
			);
		}
		this.movementManager.addMovementEvents(movementFunctions);
	}


	doubleClickObject3D(e) {
		e.preventDefault();

		const selectObject = this.selectionManager.getCollisionObject(
			this.mouseVector,
			this.checkGroup
		);

		if (!selectObject?.object?.userData?.itemId) {
			return;
		}

		this.editorRepository.setPartEditorFullscreen(false);
		this.editorRepository.setDesignerMode(DesignerMode.ITEM)
		this.editorRepository.clearArticles();
		this.editorRepository.setSelectedItems([]);
		this.itemsRepository.activateItem(selectObject.object.userData.itemId);
	}

	//// Removes the active object, enables orbit controls
	deselectObject3D() {
		this.controlsManager.controls.enableRotate = true;
		this.activeItem = null;
	}

	checkGroup(object) {
		return object.parent?.parent?.userData?.type == 'ObjectGroup';
	}

	//// Sets the selected object or removes it based upon the intersection
	selectObject3D(evt) {
		const { activeItem, selectedGroup} = this.selectionManager.selectObject3D(evt, this.selectedGroup, this.mouseVector, this.checkGroup, this.lockedAxis)
	    this.activeItem = activeItem;
		this.selectedGroup = selectedGroup;
	}


	removeEvents() {
		this.movementManager?.removeEvents();
	}

	pointerdown() {
		this.isPointerdown = true;
	}

	pointerup() {
		this.isPointerdown = false;
		this.activeCutout = null;
	}

	//// Movement function that disables orbit controls
	dragObject(evt) {
		//// Check to see if a cutout was last selected
		if (this.sceneManager.sceneType === SceneTypeEnum.ITEM_SCENE) {
			if (this.controlsManager.controls.enableRotate) return;
			if (!this.isPointerdown || !this.activeCutout) return;
			this.movementManager.dragObject(evt);
			//// Move alongside the locked plane axis
			this.selectionManager.moveObject(
				this.planeIntersect,
				this.activeCutout
			);

		} else {
			this.movementManager.dragObject(evt);
			//// Check for any active objects

			if (!this.activeItem) return;

			this.sceneManager.raycaster.ray.intersectPlane(this.selectionManager.plane, this.planeIntersect);
			this.activeItem.position.addVectors(this.planeIntersect, this.selectionManager.shift);

			this.collisionManager.checkPosition('x', this.activeItem);
			this.collisionManager.checkPosition('y', this.activeItem);
			this.collisionManager.checkPosition('z', this.activeItem);

			if (
				this.collisionManager.checkCollision(
					this.activeItem,
					this.collisionObjects
				)
			) {
				this.activeItem.position.set(
					this.activeItem.userData.lastCorrectPosition.x,
					this.activeItem.userData.lastCorrectPosition.y,
					this.activeItem.userData.lastCorrectPosition.z
				);
			} else {
				this.activeItem.userData.lastCorrectPosition =
					this.activeItem.position.clone();
			}
			const positions = this.movementManager.saveItemPositions(this.collisionObjects);
			this.positions$.next(positions);
			// first and last same for rartEditor and ItemEditor
			this.sceneManager.needToRender();
		}
	}

	checkCutout(object) {
		return object.userData.type == 'cutout';
	}

	public updateItemPositions(itemPositions: PartPosition[]): void {
		itemPositions.forEach(({ position, rotation, itemId }) => {
			const item = this.sceneManager.scene?.children.find((child) => child.userData.itemId === itemId);

			if (!item) {
				return;
			}

			item.position.x = position.x;
			item.position.y = position.y;
			item.position.z = position.z;

			item.rotation.x = rotation.x;
			item.rotation.y = rotation.y;
			item.rotation.z = rotation.z;

			this.sceneManager.needToRender();
		})
	}

	arrangeItems(noInitData: boolean, centerAllObjects: boolean) {
		if (noInitData) {
			for (let i = 0; i < this.collisionObjects.length; i++) {
				this.activeItem = this.collisionObjects[i];
				this.checkInitialPlacement();
			}
		}

		//// Checks if it should center the objects
		if (centerAllObjects) {
			centerGroups(
				getSceneDimensions(this.collisionObjects) *
				0.65,
				this.collisionObjects
			);
		}

		this.activeItem = null;
		this.sceneManager.needToRender(100);
	}



	//// Initial placement check recursive function
	checkInitialPlacement() {
		if (this.activeItem.userData.hasSavedPosition) {
			return;
		}

		const addVector = new Vector3(1, 0, 0);
		const collision = this.collisionManager.checkCollision(
			this.activeItem,
			this.collisionObjects
		);
		if (collision) {
			this.activeItem.position.add(addVector);
			this.activeItem.userData.lastCorrectPosition =
				this.activeItem.position.clone();
			this.checkInitialPlacement();
		} else {
			this.activeItem.userData.lastCorrectPosition = {
				x: 0,
				y: 0,
				z: 0,
			};
		}
	}

	//// Locks a specific axis for movement
	lockAxis(axis: 'x' | 'y' | 'z') {
		this.lockedAxis = axis;
		// this.controlsManager.disableControls();
		this.tweenManager.moveCamera(axis, cameraDistance);
	}

	addObj(url: string, details) {
		this.loader.loadFile(
			url,
			details,
			this.sceneManager.scene,
			//@ts-ignore
			(object, details, parent) => {
				//// Set transformations
				object.position.set(
					details.position.x,
					details.position.y,
					details.position.z
				);
				object.rotation.set(
					details.rotation.x,
					details.rotation.y,
					details.rotation.z
				);
				object.scale.set(
					details.scale.x,
					details.scale.y,
					details.scale.z
				);
				parent.add(object);
			}
		);
	}

	//// Rotate a collision object
	rotateObject(angle) {
		if (!this.selectedGroup) {
			return;
		}

		this.selectedGroup.rotateOnAxis(new Vector3(0, 1, 0), angle);

		if (
			this.collisionManager.checkCollision(
				this.selectedGroup,
				this.collisionObjects
			)
		) {
			this.selectedGroup.rotateOnAxis(new Vector3(0, 1, 0), -angle);
		}

		this.positions$.next(this.movementManager.saveItemPositions(this.collisionObjects));
		this.sceneManager.needToRender();
	}


	private isTypeItem(input: any): input is IBuildItemProps {
		return (input as IBuildItemProps).openDoorIds !== undefined;
	}

	private isTypePart(input: any): input is IBuildPartItemProps {
		return (input as IBuildPartItemProps).renderView !== undefined;
	}

	addCubeCutout(details: {
		width: number;
		height: number;
		depth: number;
		position: { x: number; y: number; z: number };
		rotation: { x: number; y: number; z: number };
	}) {
		const cube = this.cutoutGenerator.buildCube(
			details.width,
			details.height,
			details.depth,
			details.position,
			details.rotation
		);
		cube.userData.type = 'cutout';
		cube.userData.lockedAxis = 'z';
		cube.geometry.computeBoundingBox();
		this.sceneManager.scene.add(cube);
		this.movableObjects.push(cube);
	}


	// for displaying measurements
	/**
	 * @param boundingBox - bounding box of the object you want to add measurements
	 * @param x - optional - give the value you need to display on the x axis, or empty string i.e. "" to not show measurement line
	 * @param y - optional - give the value you need to display on the y axis, or empty string i.e. "" to not show measurement line
	 * @param z - optional - give the value you need to display on the y axis, or empty string i.e. "" to not show measurement line
	 */
	showSingleArticleZoneMeasurement(
		boundingBox: Box3,
		x?: string,
		y?: string,
		z?: string
	) {
		this.measurementsManager.articleZoneMeasurements(boundingBox, x, y, z);
	}

	showSinglePanelMeasurement(
		boundingBox: Box3,
		x?: string,
		y?: string,
		z?: string
	) {
		this.measurementsManager.panelMeasurements(boundingBox, x, y, z);
	}


	//// Pass the group you want to measure
	groupMeasurements(group: Group) {
		const geometryArr: BufferGeometry[] = [];
		for (const mesh of group.children) {
			if (mesh.children.length > 0) {
				geometryArr.push((mesh.children[0] as Mesh).geometry);
			} else {
				geometryArr.push((mesh as Mesh).geometry);
			}
		}
		const geometryFinal: BufferGeometry | null =
			mergeBufferGeometries(geometryArr);
		if (!geometryFinal)
			return console.error("Group measurements couldn't be computed");
		geometryFinal.computeBoundingBox();
		return geometryFinal;
	}
}
