import {
	Component, OnDestroy, OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
	catchError,
	combineLatest,
	filter,
	map,
	Observable,
	startWith,
	Subject,
	switchMap,
	take,
	takeUntil,
	tap,
	throwError,
} from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import {GenericItem, Item} from '~shared/types';

import { FILLER_ZONE_NAMES } from './filler-connection.const';

@Component({
	selector: 'app-filler-connection',
	templateUrl: './filler-connection.component.html'
})
export class FillerConnectionComponent implements OnInit, OnDestroy {
	public openItems: Record<string, boolean> = {
		CONNECTION_LEFT: true,
		CONNECTION_RIGHT: true,
		CONNECTION_TOP: true,
		CONNECTION_BOTTOM: true,
	}
	public selectedItem$: Observable<GenericItem>;
	public leftConstructionTypeControl = new FormControl();
	public leftConstructionTypeOptions = [];
	public rightConstructionTypeControl = new FormControl();
	public rightConstructionTypeOptions = [];
	public topConstructionTypeControl = new FormControl();
	public topConstructionTypeOptions = [];
	public bottomConstructionTypeControl = new FormControl();
	public bottomConstructionTypeOptions = [];
	public activePanel$: Observable<GenericItem>;
	public activeItem$: Observable<Item>;
	public fillerZoneNames = FILLER_ZONE_NAMES;

	private componentDestroyed$: Subject<boolean> = new Subject()

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
	) {}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.activePanel$ = this.editorRepository.selectedItems$
			.pipe(map(([item]) => item));

		this.selectedItem$ = this.editorRepository.selectedItems$
			.pipe(
				takeUntil(this.componentDestroyed$),
				map(([item]) => item),
				filter((item) => !!item),
				tap((item) => {
					this.leftConstructionTypeOptions = (item.article.construction?.leftConstructionTypeOptions || []).map((option) => ({
						icon: `/editor/connections/LEFT_${option}.svg`,
						value: option
					}));
					this.rightConstructionTypeOptions = (item.article.construction?.rightConstructionTypeOptions || []).map((option) => ({
						icon: `/editor/connections/RIGHT_${option}.svg`,
						value: option
					}));
					this.leftConstructionTypeControl.setValue(item.article.construction?.leftConstructionType, { emitEvent: false });
					this.rightConstructionTypeControl.setValue(item.article.construction?.rightConstructionType, { emitEvent: false });

					this.bottomConstructionTypeOptions = (item.article.construction?.bottomConstructionTypeOptions || []).map((option) => ({
						icon: `/editor/connections/LEFT_${option}.svg`,
						value: option
					}));
					this.topConstructionTypeOptions = (item.article.construction?.topConstructionTypeOptions || []).map((option) => ({
						icon: `/editor/connections/RIGHT_${option}.svg`,
						value: option
					}));
					this.bottomConstructionTypeControl.setValue(item.article.construction?.bottomConstructionType, { emitEvent: false });
					this.topConstructionTypeControl.setValue(item.article.construction?.topConstructionType, { emitEvent: false });
				})
			);

		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => {
				if (disabled) {
					this.leftConstructionTypeControl.disable({ emitEvent: false });
					this.rightConstructionTypeControl.disable({ emitEvent: false });
					this.topConstructionTypeControl.disable({ emitEvent: false });
					this.bottomConstructionTypeControl.disable({ emitEvent: false });
					return;
				}

				this.leftConstructionTypeControl.enable({ emitEvent: false });
				this.rightConstructionTypeControl.enable({ emitEvent: false });
				this.topConstructionTypeControl.enable({ emitEvent: false });
				this.bottomConstructionTypeControl.enable({ emitEvent: false });
			})

		combineLatest([
			this.leftConstructionTypeControl.valueChanges.pipe(startWith(null)),
			this.rightConstructionTypeControl.valueChanges.pipe(startWith(null)),
			this.topConstructionTypeControl.valueChanges.pipe(startWith(null)),
			this.bottomConstructionTypeControl.valueChanges.pipe(startWith(null))
		])
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter(([leftValue, rightValue, topValue, bottomValue]) => leftValue || rightValue || topValue || bottomValue),
				switchMap(([leftValue, rightValue, topValue, bottomValue]) => combineLatest([
					this.itemsRepository.activeItem$,
					this.selectedItem$
				])
					.pipe(take(1), map(([item, selectedItem]) => ({ leftValue, rightValue, topValue, bottomValue, item, selectedItem })))),
				tap(() => this.editorRepository.setLoading(true)),
				switchMap(({ leftValue, rightValue, item, selectedItem, topValue, bottomValue }) => {
					return this.itemsRepository
						.updateFillerConstruction(item.partId, item.id, selectedItem.outlineFaceIdx, {
							leftConstructionType: leftValue || this.leftConstructionTypeControl.value,
							rightConstructionType: rightValue || this.rightConstructionTypeControl.value,
							topConstructionType: topValue || this.topConstructionTypeControl.value,
							bottomConstructionType: bottomValue || this.bottomConstructionTypeControl.value,
						})
						.pipe(map(() => ({ leftValue, rightValue, topValue, bottomValue })))
				}),
				takeUntil(this.componentDestroyed$),
				catchError((err) => {
					this.editorRepository.setLoading(false);
					return throwError(() => err);
				}),
			)
			.subscribe(({ leftValue, rightValue, topValue, bottomValue }) => {
				this.editorRepository.setLoading(false)
				this.leftConstructionTypeControl.setValue(leftValue || this.leftConstructionTypeControl.value, { emitEvent: false });
				this.rightConstructionTypeControl.setValue(rightValue || this.rightConstructionTypeControl.value, { emitEvent: false });
				this.topConstructionTypeControl.setValue(topValue || this.topConstructionTypeControl.value, { emitEvent: false });
				this.bottomConstructionTypeControl.setValue(bottomValue || this.bottomConstructionTypeControl.value, { emitEvent: false });
			})
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public selectFiller(filler: string): void {
		this.itemsRepository.activeItem$
			.pipe(
				take(1),
				// switchMap((item) => this.itemsRepository.addItemFiller(item.id, item.partId, filler, 'CROWN')),
				take(1),
			)
			.subscribe()
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
