import {
	AmbientLight,
	Color,
	DirectionalLight,
	Fog,
	Mesh,
	MeshPhongMaterial,
	PCFSoftShadowMap,
	PlaneGeometry,
	PMREMGenerator,
	Raycaster,
	Scene,
	WebGLRenderer,
} from 'three';

import {CameraManager} from '~shared/components/cabinet-builder/managers/cameraManager';
import {cleanMaterial} from '~shared/components/cabinet-builder/utils';
import {SceneTypeEnum} from '~shared/enums';

export class SceneManager {
	public renderer?: WebGLRenderer;
	public scene?: Scene;
	public renderTime: number;
	public animationFrameId: number;
	// public controls?: OrbitControls;
	public raycaster?: Raycaster = new Raycaster();
	public width: number;
	public height: number;
	public container: HTMLElement;
	public sceneType: SceneTypeEnum;

	private backgroundColor: number;
	private fogColor: number;
	private meshParams: {width: number, height: number } ;
	private meshColor: number;

	constructor(private readonly cameraManager: CameraManager,
		container: HTMLElement,
		type: SceneTypeEnum,
		backgroundColor?: number,
		fogColor?: number,
		meshColor?: number,
		meshParams?: {width: number, height: number},
	) {
	  this.container = container;
		this.backgroundColor = backgroundColor || 0xFFFCDD;
	  this.fogColor = fogColor || 0xFFFCDD;
	  this.meshColor = meshColor || 0xFFFFFF;
	  this.meshParams = meshParams || {width: 200, height: 200};
	  this.sceneType = type;
	  // this.setScene();
	}

	setScene() {
		this.scene = new Scene();
	  this.scene.background = new Color(this.backgroundColor);
	  this.scene.fog = new Fog(this.fogColor, 30, 100 );

		// ground
		const mesh = new Mesh( new PlaneGeometry(this.meshParams.width, this.meshParams.height ),
			new MeshPhongMaterial( { color: this.meshColor, depthWrite: false } ) );
		mesh.rotation.x = - Math.PI / 2;
		mesh.receiveShadow = true;

		this.scene.add( mesh );

		//// Renderer set up
		this.renderer = new WebGLRenderer({ antialias: true });
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = PCFSoftShadowMap;
		this.renderer.autoClear = false;
		this.renderer.setPixelRatio(window.devicePixelRatio);
		const bounding = this.container.getBoundingClientRect();
		const { width, height } = bounding;
		this.width = width;
		this.height = height;
	  this.renderer.setSize(width, this.height);
	}

	initScene(container: HTMLElement): Promise<void> {
		console.log('Initializing scene...');
		if (!this.scene || !this.renderer) {
			const missingComponents = [];

			if (!this.renderer) missingComponents.push('Renderer');
			if (!this.scene) missingComponents.push('Scene');

			console.error(`Unable to initialize scene: Missing ${missingComponents.join(', ')}.`);
			return;
		}


		return new Promise<void>((resolve) => {
			const bounding = container.getBoundingClientRect();
			this.container = container;
			const { width, height } = bounding;
			this.width = width;
			this.height = height;

			this.renderer.setSize(this.width, this.height);
            this.renderer!.setSize(this.width, this.height);
            this.renderer!.render(this.scene!, this.cameraManager.camera!);
            this.container.appendChild(this.renderer!.domElement);

            console.log('add ambient light')
            const ambientLight = new AmbientLight(0xcccccc, 0.8);
            this.scene.add(ambientLight);

            const dir_light = new DirectionalLight( 0xcccccc, 0.7);
            dir_light.castShadow = true;
            dir_light.position.set(-100, 300, 300)
            this.scene.add(dir_light);

            const pmremGenerator = new PMREMGenerator(this.renderer!);
            pmremGenerator.compileEquirectangularShader();

            requestAnimationFrame(() => {
            	this.resize();
            	resolve();
            });
		}).catch((e) => console.error('Error when initializing scene: ', e));
	}

	// normal height, width set up after resize()
	resize() {
		if (!this.cameraManager.camera || !this.renderer) {
			console.log('camera', this.cameraManager.camera);
			console.log('renderer', this.renderer);
			return console.error('Camera or Renderer not defined');
		}
		const bounding = this.container.getBoundingClientRect();
		this.width = bounding.width ;
		this.height = bounding.height ;
		this.cameraManager.updateCamera(this.width, this.height);
		this.renderer.setSize(this.width, this.height);
		this.needToRender();
	}

	needToRender(value: number = 2): void {
		if (this.renderTime > 2) {
			return;
		}

		this.renderTime = value;
	}

	private animate(measurementsManager?: any) {
		if (!this.renderer || !this.scene) {
			return console.error('Renderer, Camera not defined');
		}

		const animate = () => {
			this.animationFrameId = requestAnimationFrame(animate);

			if (this.renderTime >= 1) {
				if (this.renderTime > 1) {
					this.renderTime -= 1;
					// this.controls?.update(); !!!!!!
					this.renderer?.clearDepth();
					this.renderer?.render(this.scene, this.cameraManager.camera);

					if (measurementsManager) {
						measurementsManager.rotateTextToCamera(this.cameraManager.camera);
					}
				}
			}
		};

		animate();
	}

	startRenderLoop(measurementsManager?: any) {
		if (!this.renderer) {
			return console.error('Renderer not defined')
		}

		this.animate(measurementsManager);
	}

	disposeMeshes() {
		// dispose of all meshes
		this.scene?.traverse((object) => {
			if (!(object as Mesh).isMesh) {
				return;
			}

			// dispose geometry
			(object as Mesh).geometry.dispose();
			// @ts-ignore
			if (object.material.isMaterial) {
				this.cleanMaterial((object as Mesh).material);
			} else {
				// an array of materials
				// @ts-ignore
				for (const material of object.material) {
					this.cleanMaterial(material);
				}
			}
		});

		// cancelAnimationFrame(this.animationFrameId);

		// this.renderer?.domElement?.remove();
		// delete this.renderer;
		// delete this.raycaster;
	}

	cleanMaterial(material): void {
		// dispose material
		material.dispose();
		// dispose textures
		for (const key of Object.keys(material)) {
			const value = material[key];
			if (value && typeof value === 'object' && 'minFilter' in value) {
				value.dispose();
			}
		}
	}

	disposeCurrentGroups(groups): void {
		for (let i = groups.length - 1; i >= 0; i--) {
			groups[i].traverse((object) => {
				if (!(object as Mesh).isMesh) {
					return;
				}
				// dispose geometry
				(object as Mesh).geometry.dispose();
				// @ts-ignore
				if (object.material.isMaterial) {
					cleanMaterial((object as Mesh).material);
				} else {
					// an array of materials
					// @ts-ignore
					for (const material of object.material) {
						this.cleanMaterial(material);
					}
				}
				this.scene.remove(object);
			});
			this.scene.remove(groups[i]);
		}
	}



	cleanSceneObjects(): void {
		if (!this.scene) {
			return;
		}

		while (this.scene.children?.length > 3) {
			this.scene.remove(this.scene.children[this.scene.children.length - 1]);
		}
	}

	cleanScene(): void {
		if (!this.scene) {
			return;
		}

		while (this.scene.children?.length > 0) {
			this.scene.remove(this.scene.children[0]);
		}
	}
	dispose() {
		// dispose of all meshes
		this.scene?.traverse((object) => {
			if (!(object as Mesh).isMesh) {
				return;
			}

			// dispose geometry
			(object as Mesh).geometry.dispose();
			// @ts-ignore
			if (object.material.isMaterial) {
				this.cleanMaterial((object as Mesh).material);
			} else {
				// an array of materials
				// @ts-ignore
				for (const material of object.material) {
					this.cleanMaterial(material);
				}
			}
		});
		this.cleanScene();
		cancelAnimationFrame(this.animationFrameId);

		this.renderer?.domElement?.remove();
		delete this.renderer;
		delete this.raycaster;
	}

}
