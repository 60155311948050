import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	Output, SimpleChanges,
	ViewChild,
} from '@angular/core';
import { map, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import {ArticleType, PanelType, SceneTypeEnum} from '~shared/enums/item.enum';
import {GenericItem, Item, ShapeInfo} from '~shared/types';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { SelectionType } from '~shared/shared.types';

import { EditorMode } from '~modules/projects/store/editor/editor.types';

import { SelectionManager } from './managers/selectionManager';
import {ObjectBuilder} from "~shared/components/cabinet-builder/objectBuilder";
import {TweenManager} from "~shared/components/cabinet-builder/managers/tweenManager";
import {Measurements} from "~shared/components/cabinet-builder/descoped/measurements";
import {SceneManager} from "~shared/components/cabinet-builder/managers/sceneManager";
import {CameraManager} from "~shared/components/cabinet-builder/managers/cameraManager";
import {ControlsManager} from "~shared/components/cabinet-builder/managers/controlsManager";
import {ConfigurationManager} from "~shared/components/cabinet-builder/managers/configurationManager";
import {GeneralItemEditor, IBuildItemProps} from "~shared/components/cabinet-builder/generalItemEditor";
import {ItemsRepository} from "~modules/projects/store/items/items.repository";

declare var ResizeObserver;

@Component({
	selector: 'app-cabinet-builder',
	templateUrl: './cabinet-builder.component.html',
})
export class CabinetBuilderComponent implements AfterViewInit, OnChanges, OnDestroy {
	@Input() item: Item;
	@Input() disableInteraction: boolean = false;
	@Input() hideDoors: boolean = false;
	@Input() transparentPanelTypes: PanelType[];
	@Input() isolatedItem: GenericItem;
	@Input() editorMode: EditorMode = EditorMode.DEFAULT;
	@Input() renderView: string = 'default';

	@ViewChild('container', { read: ElementRef }) containerRef: ElementRef;
	@Output() selectShape: EventEmitter<Array<ShapeInfo>> = new EventEmitter();

	public observer: ResizeObserver;

	private componentDestroyed$: Subject<boolean> = new Subject();

	itemEditor: GeneralItemEditor;
	objectBuilder: ObjectBuilder;
	tweenManager: TweenManager;
	measurementsManager: Measurements;
	selectionManager: SelectionManager;
	sceneManager: SceneManager;
	cameraManager: CameraManager;
	controlsManager: ControlsManager;
	configurationManager: ConfigurationManager;
	width: number;
	height: number;

	public container: HTMLElement;
	constructor(
		private zone: NgZone,
		private editorRepository: EditorRepository,
		private itemsRepository: ItemsRepository,
		private route: ActivatedRoute,
	) {}

	ngOnChanges(changes:SimpleChanges) {
		//// Call this once there has been a change to the item and it needs to be rebuild
		this.rebuildItem();
	}

	ngOnDestroy() {
		this.sceneManager.dispose();
		this.cameraManager.disposeCamera();
		this.controlsManager.disposeControls();
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	ngAfterViewInit(): void {
		// We have to run this outside angular zones,
		// because it could trigger heavy changeDetection cycles.
		this.observer = new ResizeObserver((entries) => {
			this.editorRepository.setContainer(this.containerRef.nativeElement);
			this.sceneManager.resize();
		});
		this.observer.observe(this.containerRef.nativeElement);
		this.zone.runOutsideAngular(() => this.initialise());
		this.editorRepository.setContainer(this.containerRef.nativeElement);

		const object = this.route.snapshot.queryParams.object;

		if (object) {
			const mesh = this.selectionManager.findIdInScene(object);

			if (mesh?.userData?.object) {
				this.editorRepository.setSelectedItems([mesh?.userData?.object]);
				this.itemEditor.lastSelectedObj = mesh;
				this.handleConfigurationPanel(mesh?.userData?.object);
			}
		}

		this.editorRepository.selectedItems$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((items) => {
				this.itemEditor.highlightObjects(items, this.isolatedItem);
			});

		this.editorRepository.openDoorIds$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((doorIds) => {
				this.itemEditor.triggerDoors(true, doorIds)
			})
	}

	initialise() {
		this.init(this.containerRef.nativeElement);
		this.initItemEditor();
		if (this.item) {
			this.buildItem();
		}
	}

	init(container: HTMLElement) {
		while (container.firstChild) {
			container.removeChild(container.firstChild);
		}

		const bounding = container.getBoundingClientRect();

		console.log(container);
		const { width, height } = bounding;

		this.container = container;

		console.log('init_params_item', width, height);

		if (this.sceneManager && this.cameraManager) {
			this.sceneManager.resize();
			return;
		}
		//// Init managers
		this.cameraManager = new CameraManager();
		this.cameraManager.initializeCamera(container);
		console.log('initCamera', this.cameraManager);
		this.sceneManager = new SceneManager(this.cameraManager, this.container, SceneTypeEnum.ITEM_SCENE);
		this.sceneManager.setScene();
		console.log('initScene', this.sceneManager);
		this.controlsManager = new ControlsManager(this.cameraManager, this.sceneManager);
		this.configurationManager = new ConfigurationManager(this.sceneManager);

		this.tweenManager = new TweenManager(this.cameraManager, this.sceneManager, this.controlsManager);
		this.measurementsManager = new Measurements(this.sceneManager.scene);
		this.selectionManager = new SelectionManager(
			this.cameraManager,
			this.sceneManager,
			this.controlsManager,
			this.editorRepository
		);
		this.objectBuilder = new ObjectBuilder(this.sceneManager);
		this.cameraManager.updateCamera(this.width, this.height);
		return this.sceneManager.initScene(this.container);
	}

	initItemEditor() {
		if (this.itemEditor) {
			return;
		}
		this.itemEditor = new GeneralItemEditor(
			this.objectBuilder,
			this.measurementsManager,
			this.selectionManager,
			this.editorRepository,
			this.cameraManager,
			this.sceneManager,
			this.controlsManager,
			this.configurationManager,
			this.itemsRepository,
			this.tweenManager
		);
		this.itemEditor.addMovementEvents();
	}

	buildItem() {
		// Initial build
		this.editorRepository.openDoorIds$
			.pipe(take(1))
			.subscribe((openDoorIds) => {
				this.itemEditor.build({
					renderView: this.renderView,
					item: this.item,
					transparentPanelTypes: this.transparentPanelTypes,
					isolatedItem: this.isolatedItem,
					openDoorIds,
					hideDoors: this.hideDoors,
					editorMode: this.editorMode
				} as IBuildItemProps);
			})

		this.animateDuplicate();
	}

	animateDuplicate(): void {
		// We have to run this outside angular zones,
		// because it could trigger heavy changeDetection cycles.
		this.zone.runOutsideAngular(() => {
			if (document.readyState !== 'loading') {
				this.sceneManager.needToRender(100);
			} else {
				window.addEventListener('DOMContentLoaded', () => {
					this.sceneManager.needToRender(100);
				});
			}

			window.addEventListener('resize', () => {
				this.sceneManager.resize();
			});
		});
	}
	public handleConfigurationPanel(object?: GenericItem): void {
		if (this.editorMode !== EditorMode.DEFAULT) {
			return;
		}

		if (
			SelectionManager.getSelectionType(object) ===
			SelectionType.DOOR
		) {
			this.editorRepository.setFillerHighlight(null);
			return this.editorRepository.setActiveConfigurationPanel('DOOR');
		}

		if (
			SelectionManager.getSelectionType(object) ===
			SelectionType.ARTICLE_ZONE
		) {
			this.editorRepository.setFillerHighlight(null);
			return this.editorRepository.setActiveConfigurationPanel('LAYOUT');
		}

		if (
			SelectionManager.getSelectionType(object) ===
			SelectionType.FACE
		) {
			this.editorRepository.setFillerHighlight(null);
			return this.editorRepository.setActiveConfigurationPanel('FACE');
		}

		if (
			SelectionManager.getSelectionType(object) ===
			SelectionType.ARTICLE
		) {
			if (object.article.articleType === ArticleType.DRAWER) {
				return this.editorRepository.setActiveConfigurationPanel(
					`DRAWER`
				);
			}

			if (object.article.articleType === ArticleType.SIDEMOUNTED_ROD) {
				return this.editorRepository.setActiveConfigurationPanel(
					`SIDEMOUNTED_ROD`
				);
			}

			if (object.article.articleType === ArticleType.SUSPENDED_ROD) {
				return this.editorRepository.setActiveConfigurationPanel(
					`SUSPENDED_ROD`
				);
			}

			if (object.panelType === PanelType.BASE_BACK) {
				return this.editorRepository.setActiveConfigurationPanel(
					`BASE`
				);
			}

			if (object.panelType === PanelType.BASE_FRONT) {
				return this.editorRepository.setActiveConfigurationPanel(
					`BASE`
				);
			}

			if (object.panelType === PanelType.DIVIDER) {
				// TODO: actually get the orientation from the backend, it currently is not passed.
				return this.editorRepository.setActiveConfigurationPanel(
					`XZ_DIVIDER`
				);
			}

			if (object.panelType === PanelType.BACK) {
				return this.editorRepository.setActiveConfigurationPanel(
					'BACK'
				);
			}

			if ([PanelType.FILLER_FRONT, PanelType.FILLER_RECESSED, PanelType.FILLER_PANEL].includes(object.panelType)) {
				this.editorRepository.setActiveConfigurationPanel(
					`FILLER_CONNECTION`
				);
				return this.editorRepository.setSelectedFaceIdx(object.outlineFaceIdx);
			} else {
				this.editorRepository.setSelectedFaceIdx(null);
			}

			if (object.panelType === PanelType.FILLER_SUPPORT) {
				this.editorRepository.setActiveConfigurationPanel(
					`FILLER_SUPPORT`
				);
				return this.editorRepository.setSelectedFaceIdx(object.outlineFaceIdx);
			} else {
				this.editorRepository.setSelectedFaceIdx(null);
			}

			if (object.panelGroup === ArticleType.CARCASE) {
				return this.editorRepository.setActiveConfigurationPanel(
					'CARCASE'
				);
			}

			if (object.panelType === PanelType.SHELF) {
				return this.editorRepository.setActiveConfigurationPanel(
					'SHELVES'
				);
			}

			if (object.panelType === PanelType.FRAME) {
				return this.editorRepository.setActiveConfigurationPanel(
					'FRAME'
				);
			}

			this.editorRepository.setActiveConfigurationPanel(null);
		} else {
			this.editorRepository.setActiveConfigurationPanel(null);
			this.editorRepository.setSelectedFaceIdx(null);
		}
	}

	select3dObject(e: PointerEvent) {
		if (!this.sceneManager.renderer || this.disableInteraction) {
			return;
		}

		// ctrlKey = ctrl on windows
		// metaKey = command on mac
		const result = this.itemEditor.selectObject(
			e.offsetX,
			e.offsetY,
			e.ctrlKey || e.metaKey
		);

		if (!result) {
			return;
		}

		const { primaryObjects } = result;
		const firstShapeInfo = primaryObjects?.[0];

		this.editorRepository.setSelectedItems(
			(primaryObjects || []).map((o) => o?.object)
		);

		this.handleConfigurationPanel(firstShapeInfo?.object);
	}

	isolate3dObject(e: PointerEvent) {
		if (!this.sceneManager.renderer || this.disableInteraction) {
			return;
		}

		this.editorRepository.setOpenDoorsIds([]);
		this.editorRepository.setMode(EditorMode.DEFAULT);

		// ctrlKey = ctrl on windows
		// metaKey = command on mac
		const result = this.itemEditor.selectObject(
			e.offsetX,
			e.offsetY,
			e.ctrlKey || e.metaKey
		);

		this.editorRepository.isolatedItem$
			.pipe(take(1))
			.subscribe((item) => {
				if (item) {
					this.editorRepository.setSelectedItems([]);
					this.editorRepository.setActiveConfigurationPanel(null);
					return this.editorRepository.setIsolatedItem(null);
				}

				this.editorRepository.setSelectedItems([result.primaryObjects[0]?.object]);
				this.editorRepository.setIsolatedItem(result.primaryObjects[0]?.object);
			})
	}

	onRightClick(event: Event): void {
		// this will disable default action of the context menu
		event.preventDefault();
	}

	startTimer() {
		this.itemEditor.startTimer();
	}

	endTimer() {
		this.itemEditor.endTimer();
	}

	rebuildItem() {
		if (
			this.sceneManager && this.itemEditor &&
			this.sceneManager.renderer != null && this.item
		) {
			this.sceneManager.cleanSceneObjects();
			this.buildItem();
		}
	}
}
