import { select } from '@ngneat/elf';
import {selectActiveEntities, selectActiveEntity, selectAllEntities, selectAllEntitiesApply } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { getStore, getStoresSnapshot  } from '@ngneat/elf';
import { itemStore } from './items.store';
import { partStore } from '../parts/parts.store';
import { combineLatest, map} from 'rxjs';
import {partsSelector} from "~modules/projects/store/parts/parts.selectors";

// @ts-ignore
export const itemsSelector = {
	items$: itemStore.pipe(selectAllEntities()),
	itemsLoading$: itemStore.pipe(
		selectRequestStatus('items'),
		map((status) => status.value === 'pending')
	),
	deletePanelLoading$: itemStore.pipe(
		selectRequestStatus('delete-panel'),
		map((status) => status.value === 'pending')
	),
	deleteArticleLoading$: itemStore.pipe(
		selectRequestStatus('delete-article'),
		map((status) => status.value === 'pending')
	),
	recreateBackLoading$: itemStore.pipe(
		selectRequestStatus('recreate-back'),
		map((status) => status.value === 'pending')
	),
	updateItemDimensionLoading$: itemStore.pipe(
		selectRequestStatus('update-item-dimension'),
		map((status) => status.value === 'pending')
	),
	activeItem$: itemStore.pipe(selectActiveEntity()),
	tempNewItem$: itemStore.pipe(select((state) => state.tempNewItem)),
	partItems$: itemStore.pipe(select((state) => state.partItems)),
	activePartItems$: combineLatest([
		partStore.pipe(select((state) => state.activeId)), // Replace with the actual selector for activePart$
		itemStore.pipe(selectAllEntities()),
	]).pipe(
		map(([activePart, items]) =>
			items?.filter((item) =>
			{
				return item?.partId === activePart;
			})
		)
	)
};
