import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';

import { connectionOptions, connectionsMap } from './panel-connection.const';
import {GenericItem} from "~shared/types";

@Component({
	selector: 'app-panel-connection',
	templateUrl: './panel-connection.component.html',
})
export class PanelConnectionComponent implements OnInit, OnDestroy {
	public connectionOptions = [];
	public show = false;
	public isOpen = true;
	public selectedPanel$: Observable<GenericItem>;
	public connectionControl: FormControl = new FormControl();

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
	) {}

	public ngOnInit(): void {
		this.editorRepository.selectedItems$
			.pipe(
				takeUntil(this.componentDestroyed$),
				map(([panel]) => panel),
				filter((panel) => !!panel),
				tap((panel) => {
					this.connectionOptions = connectionOptions.filter(({ value }) => connectionsMap[panel.panelType]?.[panel.backPanelType || 'DEFAULT']?.includes(value));

					if (this.connectionOptions.length === 1) {
						this.connectionControl.disable({ emitEvent: false });
					} else {
						this.connectionControl.enable({ emitEvent: false });
					}

					if (this.connectionOptions.length) {
						this.connectionControl.setValue(panel.connectorType, { emitEvent: false })
						this.show = true;
					} else {
						this.show = false;
					}
				}),
			).subscribe();

		this.connectionControl.valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => this.updateConnection())
	}

	public updateConnection(): void {
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.selectedItems$])
			.pipe(
				take(1),
				switchMap(([item, [panel]]) => this.itemsRepository.updatePanelConnection(item.partId, item.id, panel.article.id, panel.id, this.connectionControl.value, {
					connectionType: this.connectionControl.value,
				})),
				take(1)
			).subscribe()
	}

	public toggleItem(): void {
		this.isOpen = !this.isOpen
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
