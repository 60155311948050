import { Pipe, PipeTransform } from '@angular/core';

import Mexp from "math-expression-evaluator";

@Pipe({
	name: 'inputEval',
	pure: false,
})
export class InputToEvalPipe implements PipeTransform {
	transform(values: { [key: string]: any }):  { [key: string]: any } {
		const evaluated: { [key: string]: any } = {};
		for (const key in values) {
			if (values.hasOwnProperty(key) && values[key]) {
				evaluated[key] = this.evaluateInput(values[key]);
			} else {
				evaluated[key] = values[key];
			}
		}
		return evaluated;
	}

	evaluateInput(input: string) {
		if (input) {
			const mexp: Mexp = new Mexp();
			try {
				const evaluated = Math.ceil(mexp.eval(input));
				return evaluated > 0 ? evaluated : input;
			} catch (e) {
				return input
			}
		}
		return input;
	}
}
