import {Subject} from "rxjs";
import {PartPosition} from "~shared/shared.types";
import {SceneManager} from "~shared/components/cabinet-builder/managers/sceneManager";
import {CameraManager} from "~shared/components/cabinet-builder/managers/cameraManager";
import {GeneralItemEditor} from "~shared/components/cabinet-builder/generalItemEditor";

export class MovementManager {
	events: Array<{ funct: () => void; eventName: string }> = [];
	public positions$: Subject<PartPosition[]> = new Subject();
	public transformValues: PartPosition[];

	constructor(private readonly mouseVector,
				private readonly sceneManager: SceneManager,
				private readonly cameraManager: CameraManager) {}

	dragObject(evt) {
		//// Check which axis is locked
		this.mouseVector.x =
			(evt.offsetX / this.sceneManager.container.clientWidth) * 2 - 1;
		this.mouseVector.y =
			-(evt.offsetY / this.sceneManager.container.clientHeight) * 2 + 1;
		this.sceneManager.raycaster.setFromCamera(
			this.mouseVector,
			this.cameraManager.camera
		);
	}

	moveObject(plane, planeIntersect, shift, object) {
		//// Move alongside the locked plane axis
		this.sceneManager.raycaster.ray.intersectPlane(plane, planeIntersect);
		object.position.addVectors(planeIntersect, shift);
	}

	addMovementEvents(
		eventFunctions: Array<{
			funct: (evt?) => void;
			eventName: string;
			editor: GeneralItemEditor;
		}>
	) {
		for (const funcInfo of eventFunctions) {
			funcInfo.funct = funcInfo.funct.bind(funcInfo.editor);
			this.sceneManager.renderer.domElement.addEventListener(
				funcInfo.eventName,
				funcInfo.funct
			);
			this.events.push({
				funct: funcInfo.funct,
				eventName: funcInfo.eventName,
			});
		}
	}

	removeEvents() {
		if (!this.sceneManager.renderer) {
			return;
		}

		for (const event of this.events) {
			this.sceneManager.renderer.domElement.removeEventListener(
				event.eventName,
				event.funct
			);
		}
	}

	saveItemPositions(collisionObjects: any) {
		//// Clear previous entries
		this.transformValues = [];
		//// Save positions and rotation
		for (const object of collisionObjects) {
			this.transformValues.push({
				position: {
					x: object.position.x,
					y: object.position.y,
					z: object.position.z,
				},
				rotation: {
					x: object.rotation.x,
					y: object.rotation.y,
					z: object.rotation.z,
				},
				itemId: object.userData.itemId,
				partId: object.userData.partId,
				hasPrefilledPosition: !!object.userData.hasSavedPosition,
			});
		}

		return this.transformValues;
	}

}
