import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {BehaviorSubject, combineLatest, map, Observable, of, Subject, switchMap, take, takeUntil, tap} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ItemsRepository} from "~modules/projects/store/items/items.repository";
import {EditorRepository} from "~modules/projects/store/editor/editor.repository";
import {PartEditorRepository} from "~modules/projects/store/part-editor/part-editor.repository";
import {Button, StandardDialogComponent} from "~shared/components/standard-dialog/standard-dialog.component";
import {Item, Part} from "~shared/types";
import {PartEditorButtons} from "~shared/components/cabinet-builder/builder-config";
import {PanelType} from "~shared/enums";
import {GeneralItemEditor} from "~shared/components/cabinet-builder/generalItemEditor";

@Component({
	selector: 'app-controls-buttons',
	templateUrl: './controls-buttons.component.html',
})
export class ControlsButtonsComponent implements OnInit, OnDestroy {
	@Input() componentType: string;
	@Input() part: Part;
	@Input() partEditor: GeneralItemEditor;
	@Output() toggleDoorEvt: EventEmitter<boolean> = new EventEmitter();
	public lastView$ = new BehaviorSubject('z');
	public buttons$: Observable<
		{ type: string; icon?: string; action?: string }[]
	>;
	private componentDestroyed$: Subject<boolean> = new Subject();
	public doorsTransparent$: BehaviorSubject<boolean> = new BehaviorSubject(
		false
	);

	constructor(
		private readonly dialog: MatDialog,
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
		private readonly partEditorRepository: PartEditorRepository
	) {}
	ngOnInit() {
		this.buttons$ = combineLatest([
			this.editorRepository.selectedItems$,
			this.lastView$,
			this.partEditorRepository.hasFuture$,
			this.partEditorRepository.hasPast$,
		]).pipe(
			takeUntil(this.componentDestroyed$),
			switchMap(([items, lastView, hasFuture, hasPast]) => {
				return of(
					PartEditorButtons(lastView, items, hasFuture, hasPast)
				);
			})
		);
	}

	ngOnDestroy() {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	// controls manager
	onButtonClick(action: string): void {
		switch (action) {
			case 'x':
				this.partEditor.lockAxis(action);
				this.lastView$.next('x');
				break;
			case 'y':
				this.partEditor.lockAxis(action);
				this.lastView$.next('y');
				break;
			case 'z':
				this.partEditor.lockAxis(action);
				this.lastView$.next('z');
				break;
			case 'ROTATE_ANTI_CLOCKWISE':
				this.partEditor.rotateObject(1.5708);
				break;
			case 'DELETE':
				this.onDeleteSelectedItem();
				break;
			case 'ROTATE_CLOCKWISE':
				this.partEditor.rotateObject(-1.5708);
				break;
			case 'REDO':
				this.partEditorRepository.redo();
				this.partEditorRepository.itemPositions$
					.pipe(
						take(1),
						switchMap((positions) =>
							combineLatest(
								positions.map((position) =>
									this.itemsRepository.updateItemCoordinates(
										position.itemId,
										position.partId,
										{
											partCoordinate: position.position,
											partRotation: position.rotation,
										}
									)
								)
							)
						),
						take(1)
					)
					.subscribe();
				break;
			case 'UNDO':
				this.partEditorRepository.undo();
				this.partEditorRepository.itemPositions$
					.pipe(
						take(1),
						switchMap((positions) =>
							combineLatest(
								positions.map((position) =>
									this.itemsRepository.updateItemCoordinates(
										position.itemId,
										position.partId,
										{
											partCoordinate: position.position,
											partRotation: position.rotation,
										}
									)
								)
							)
						),
						take(1)
					)
					.subscribe();
				break;
			default:
				break;
		}
	}
	onDeleteSelectedItem(): void {
		this.editorRepository.partEditorSelectedItem$
			.pipe(
				take(1),
				switchMap((itemId) => {
					return this.itemsRepository.items$.pipe(
						take(1),
						map((items) => items.find((item) => item.id === itemId))
					);
				})
			)
			.subscribe((item) => {
				const dialogRef = this.dialog.open(StandardDialogComponent, {
					data: {
						title: 'Item verwijderen',
						body: `Bent u zeker dat u dit item "${item.name}" wilt verwijderen?`,
						buttons: [
							new Button('Annuleren'),
							new Button('OK', 'CONFIRM', 'primary'),
						],
						icon: 'status',
						type: 'warning',
					},
				});

				dialogRef.afterClosed().subscribe((action) => {
					if (action !== 'CONFIRM') {
						return;
					}

					this.itemsRepository
						.deleteItem(item.partId, item.id)
						.pipe(
							take(1),
							switchMap(() =>
								this.itemsRepository
									.getItems([this.part.id])
									.pipe(take(1))
							)
						)
						.subscribe();
				});
			});
	}

	public toggleDoorTransparency(shouldOpen: boolean): void {
		const items$ =
			this.componentType === 'partEditor'
				? this.itemsRepository.items$
				: this.itemsRepository.partItems$;
		combineLatest([of(shouldOpen), items$])
			.pipe(
				take(1),
				tap(([isOpen, items]: [boolean, Item[]]) => {
					const doorIds = items.reduce(
						(acc, item) => [...acc, ...this.getDoorIds(item)],
						[]
					);
					this.partEditor.triggerDoors(
						true,
						isOpen ? doorIds : []
					);
					this.doorsTransparent$.next(shouldOpen);
				})
			)
			.subscribe();
	}

	public toggleItemDoorTransparency(shouldOpen: boolean) {
		this.doorsTransparent$.next(shouldOpen);
		this.toggleDoorEvt.emit(shouldOpen);
	}

	private getDoorIds(item: Item): string[] {
		return item?.articles?.reduce((articleAcc, article) => {
			return [
				...articleAcc,
				...(article?.panels || [])?.reduce((panelAcc, panel) => {
					if (
						![PanelType.FRONT, PanelType.DRAWER_FRONT].includes(
							panel.panelType
						)
					) {
						return panelAcc;
					}

					return [panel.id, ...panelAcc];
				}, []),
			];
		}, []);
	}
}
