import { gsap } from 'gsap';
import {
	Group,
	MathUtils,
	Mesh,
	Object3D,
	PerspectiveCamera,
	Quaternion,
	Vector3,
} from 'three';

import {ControlsManager} from "~shared/components/cabinet-builder/managers/controlsManager";
import {CameraManager} from "~shared/components/cabinet-builder/managers/cameraManager";
import {SceneManager} from "~shared/components/cabinet-builder/managers/sceneManager";

export class TweenManager {
	public tweens: Array<{
		openTween: gsap.core.Tween;
		closeTween: gsap.core.Tween;
		id: string;
	}> = [];

	constructor(private readonly cameraManager: CameraManager,
				private readonly sceneManager: SceneManager,
				private readonly controlsManager: ControlsManager) {}


	rotateCamera(
		camera: PerspectiveCamera,
		toVal: Vector3,
	)
	{
		camera.rotation.set(toVal.x, toVal.y, toVal.z)
		this.controlsManager.controls.enabled = true;		//enable orbitControl again
		this.controlsManager.controls.update();
		this.sceneManager.needToRender();
	}
	//// Camera movement tween
	moveCameraOnAxis(
		camera: PerspectiveCamera,
		axis: 'x' | 'y' | 'z',
		toVal: number,
		duration: number
	) {
		camera.position[axis] = toVal;
		this.sceneManager.needToRender();
		// gsap.to(camera.position, {
		// 	[axis]: toVal,
		// 	duration: duration,
		// 	onUpdate: () => {
		// 		this.renderService.needToRender();
		// 	},
		// });
	}

	addMovementTween(obj: Object3D, duration: number, offsetValue: number){
		const offset = offsetValue
		const moveTween = gsap.fromTo(obj.position,
			{
				x: obj.position.x,
				y: obj.position.y,
				z: obj.position.z
			},
			{
				x: obj.position.x,
				y: obj.position.y,
				z: obj.position.z + offset,
				onUpdate: () => this.sceneManager.needToRender(60),
				paused: true,
				duration,
			}
		)
		return moveTween
	}

	//// Moves the camera to the specified point of view
	moveCamera(axis: 'x' | 'y' | 'z', cameraDistance: number) {
		switch (axis) {
			case 'x':
				this.moveCameraOnAxis(
					this.cameraManager.camera,
					axis,
					cameraDistance,
					1
				);
				this.moveCameraOnAxis(
					this.cameraManager.camera,
					'y',
					0,
					1
				);
				this.moveCameraOnAxis(
					this.cameraManager.camera,
					'z',
					0,
					1
				);
				this.rotateCamera(
					this.cameraManager.camera,
					this.cameraManager.defaultCameraTopRotation
				);
				break;
			case 'y':
				this.moveCameraOnAxis(
					this.cameraManager.camera,
					axis,
					cameraDistance,
					1
				);
				this.moveCameraOnAxis(
					this.cameraManager.camera,
					'z',
					0,
					1
				);
				this.moveCameraOnAxis(
					this.cameraManager.camera,
					'x',
					0,
					1
				);
				this.rotateCamera(
					this.cameraManager.camera,
					this.cameraManager.defaultCameraTopRotation
				);

				break;
			case 'z':
				this.moveCameraOnAxis(
					this.cameraManager.camera,
					axis,
					cameraDistance,
					1
				);
				this.moveCameraOnAxis(
					this.cameraManager.camera,
					'y',
					0,
					1
				);
				this.moveCameraOnAxis(
					this.cameraManager.camera,
					'x',
					0,
					1
				);
				this.rotateCamera(
					this.cameraManager.camera,
					this.cameraManager.defaultCameraTopRotation
				);
				break;
			default:
				break;
		}
	}

}
