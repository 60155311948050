import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { filter, map, Observable, Subject, takeUntil } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import {GenericItem, Item} from '~shared/types';

@Component({
	templateUrl: './suspended-rod.component.html',
})
export class SuspendedRodComponent implements OnInit, OnDestroy {
	public configurationForm: FormGroup<{
		id: FormControl<string>
	}>;
	public includeDividerDisabled = false;
	public openItems: Record<string, boolean> = {
		GENERAL: true,
		OFFSET: true
	};

	private componentDestroyed$: Subject<boolean> = new Subject()
	public activePanel$: Observable<GenericItem>;
	public disableField$: Observable<boolean>;
	public activeItem$: Observable<Item>;
	public adjustmentLevelLoading = null;

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
		private readonly fb: FormBuilder
	) {}

	@HostListener('document:keydown.escape', ['$event'])
	public onKeydownHandler(event: KeyboardEvent) {
		this.initializeDefaults();
	}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.activePanel$ = this.editorRepository.selectedItems$
			.pipe(map(([item]) => item));

		this.configurationForm = this.fb.group({
			id: ['new']
		});

		this.disableField$ = this.editorRepository.disableFields$;
		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => disabled
				? this.configurationForm.disable({ emitEvent: false })
				: this.configurationForm.enable({ emitEvent: false }))

		this.initializeDefaults();
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public initializeDefaults(): void {
		this.editorRepository.selectedArticles$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((articles) => !!articles && !!articles.length)
			)
			.subscribe(([article]) => {
				this.configurationForm.reset({}, { emitEvent: false });
				this.configurationForm.patchValue({
					id: article.id,
				}, { emitEvent: false })
			})
	}
}
