import {
	Color,
	Mesh,
	MeshBasicMaterial,
	Vector2,
	Object3D,
	Group,
	Raycaster,
	Material, Vector3, Plane,
} from 'three';

import { PanelType } from 'src/app/shared/enums';
import { getDefaultMaterial } from 'src/app/shared/helpers';
import { SelectionType } from 'src/app/shared/shared.types';

import {bufferMaterialMap, customisedMaterialMap} from "~shared/components/cabinet-builder/utils";
import {EditorMode} from "~modules/projects/store/editor/editor.types";
import {CameraManager} from "~shared/components/cabinet-builder/managers/cameraManager";
import {SceneManager} from "~shared/components/cabinet-builder/managers/sceneManager";
import {ControlsManager} from "~shared/components/cabinet-builder/managers/controlsManager";
import {EDITOR_STATE_COLOURS} from "~shared/shared.const";
import {GenericItem, IArticle, IArticleZone, ShapeInfo} from "~shared/types";
import {EditorRepository} from "~modules/projects/store/editor/editor.repository";

export class SelectionManager {
	selectedObjects: Mesh[] = [];
	selectedPrimaryObjects: ShapeInfo[] = [];
	lastIntersection?;
	public pIntersect = new Vector3(); // point of intersection with an object (plane's point)
	public pNormal = {
		x: new Vector3(1, 0, 0),
		y: new Vector3(0, 1, 0),
		z: new Vector3(0, 0, 1),
	}; // plane's normal
	public activeCutout?: Mesh;
	public shift = new Vector3(); // distance between position of an object and points of intersection with the object
	public plane = new Plane();

	constructor(
		private readonly cameraManager: CameraManager,
		private readonly sceneManager: SceneManager,
		private readonly controlsManager: ControlsManager,
		private readonly editorRepository: EditorRepository,

	) {}

	getIntersections(from: Vector2, isolatedItem?: GenericItem): Mesh {
		// Again, item editor only function.
		this.sceneManager.raycaster.setFromCamera(from, this.cameraManager.camera);

		const intersections = this.sceneManager.raycaster.intersectObjects(
			this.sceneManager.scene.children,
			true
		);

		if (intersections && intersections.length) {
			for (let intersect of intersections) {
				if (
					isolatedItem &&
					isolatedItem?.id === intersect.object?.userData?.isolationId
				) {
					this.lastIntersection = intersect;
					return intersect.object as Mesh;
				}

				if (
					!isolatedItem &&
					intersect.object.type != 'FloorPlan' &&
					!intersect.object.userData.isEdgeLine &&
					!intersect.object.userData.isClickableFace &&
					!intersect.object.userData.disallowSelection
				) {
					this.lastIntersection = intersect;
					return intersect.object as Mesh;
				}
			}
		}

		return undefined;
	}

	getSelectedShapesInfo(): Array<ShapeInfo> {
		return this.selectedObjects.map((object) => this.getShapeInfo(object));
	}

	getShapeInfo(object: Mesh): ShapeInfo {
		if (!object) {
			return null;
		}
		return {
			type: object.userData.type || object.parent.userData.type,
			object: object.userData.object || object.parent.userData.object,
		};
	}

	static getSelectionType(item?: any): SelectionType {
		if (!item) {
			return null;
		}

		if (item.type === 'face') {
			return SelectionType.FACE;
		}

		if (item.articleZone) {
			return SelectionType.ARTICLE_ZONE;
		}

		if (item.panelType === PanelType.FRONT) {
			return SelectionType.DOOR;
		}

		if (item.article) {
			return SelectionType.ARTICLE;
		}
	}

	handleObjectSelection(object: Mesh) {
		for (let child of object.parent.children) {
			this.selectedObjects.push(child as Mesh);
		}

		this.selectedObjects.push(object);
		this.selectedPrimaryObjects.push(this.getShapeInfo(object));
	}

	deselectAll(transparentPanelTypes: PanelType[], isolatedItem: GenericItem) {
		this.removeHighlight(
			this.sceneManager.scene.children,
			transparentPanelTypes,
			isolatedItem
		);

		this.selectedObjects = [];
		this.selectedPrimaryObjects = [];
	}

	private removeHighlight(
		children,
		transparentPanelTypes: PanelType[],
		isolatedItem: GenericItem
	): void {
		children.forEach((item) => {
			if (
				item.userData.isEdgeLine ||
				item.userData.type == 'FloorPlan'
			) {
				return;
			}

			item.userData.highlighted = false;
			// If the mesh had a temporary mat applied, use that to remove the highlight
			if (item.userData?.temporaryMaterial) {
				item.material?.dispose();
				item.material = item.userData?.temporaryMaterial;

				return this.removeHighlight(
					item.children || [],
					transparentPanelTypes,
					isolatedItem
				);
			}

			if (item.userData?.originalMaterial) {
				item.material?.dispose();
				item.material = item.userData?.originalMaterial;

				return this.removeHighlight(
					item.children || [],
					transparentPanelTypes,
					isolatedItem
				);
			}

			this.removeHighlight(
				item.children || [],
				transparentPanelTypes,
				isolatedItem
			);
		});
	}

	getCollisionObject(
		from: Vector2,
		checkCondition: (object) => boolean
	): {
		object: Group | Object3D;
		intersection;
	} {
		// This function is ony called in the part editor
		this.sceneManager.raycaster.setFromCamera(from, this.cameraManager.camera);

		const intersections = this.sceneManager.raycaster.intersectObjects(
			this.sceneManager.scene.children,
			true
		);

		if (intersections && intersections.length) {
			for (let intersect of intersections) {
				if (
					intersect.object.userData.type != 'FloorPlan' &&
					!intersect.object.userData.isEdgeLine &&
					!intersect.object.userData.isClickableFace &&
					!intersect.object.userData.disallowSelection &&
					checkCondition(intersect.object)
				) {
					return {
						object: intersect.object.parent.parent,
						intersection: intersect,
					};
				}
			}
		}

		return undefined;
	}

	selectObjectDiff(mouseX, mouseY, container, isolatedItem?: GenericItem) {
		// This function is used in the item editor

		const mouseVector = new Vector2();
		mouseVector.x = (mouseX / container.clientWidth) * 2 - 1;
		mouseVector.y = -(mouseY / container.clientHeight) * 2 + 1;
		const selectObject = this.getIntersections(mouseVector, isolatedItem);

		const isSelectionOfDifferentType = !this.selectedPrimaryObjects.find(
			(obj) => {
				return (
					SelectionManager.getSelectionType(obj.object) ===
					SelectionManager.getSelectionType(
						selectObject?.userData?.object as GenericItem
					)
				);
			}
		);

		return [selectObject, isSelectionOfDifferentType];
	}

	selectObject(
		mouseX: number,
		mouseY: number,
		isCtrlDown: boolean,
		isClickAndHold: boolean,
		lastSelectedObj: Mesh,
		transparentPanelTypes: PanelType[],
		isolatedItem: GenericItem,
		editorMode: EditorMode
	): {
		primaryObjects: ShapeInfo[];
		allObjects: ShapeInfo[];
		isClickAndHold: boolean;
		lastSelectedObj: Mesh
	} {
		if (isClickAndHold) {
			isClickAndHold = false;
			return {
				primaryObjects: [],
				allObjects: [],
				isClickAndHold,
				lastSelectedObj
			};
		}

		const selection = this.selectObjectDiff(
			mouseX,
			mouseY,
			this.sceneManager.container,
			isolatedItem,
		);
		const selectionType = SelectionManager.getSelectionType(
			(selection[0] as Mesh)?.userData?.object as GenericItem
		);

		const isSelectionOfDifferentType =
			!this.selectedPrimaryObjects?.find((obj) => {
				return (
					SelectionManager.getSelectionType(obj.object) ===
					SelectionManager.getSelectionType(
						(selection[0] as Mesh)?.userData?.object as GenericItem
					)
				);
			});

		if (lastSelectedObj && !isCtrlDown) {
			this.deselectAll(transparentPanelTypes, isolatedItem);
		}

		if (lastSelectedObj && isCtrlDown && isSelectionOfDifferentType) {
			this.deselectAll(transparentPanelTypes, isolatedItem);
		}

		if ((selection[0] && editorMode === EditorMode.DEFAULT) || (selectionType === SelectionType.ARTICLE_ZONE && editorMode === EditorMode.SELECT_ARTICLE_ZONES)) {
			lastSelectedObj = selection[0] as Mesh;
			this.handleObjectSelection(lastSelectedObj);
		}

		if (selection[0] && this.checkCutout(selection[0])) {
			this.selectCutout(true, selection[0] as Mesh);
		} else {
			this.selectCutout(false);
		}

		this.sceneManager.needToRender(60);
		isClickAndHold = false;

		return {
			primaryObjects: this.selectedPrimaryObjects,
			allObjects: this.getSelectedShapesInfo(),
			isClickAndHold,
			lastSelectedObj
		};
	}

	//// Sets the selected object or removes it based upon the intersection
	selectObject3D(evt, selectedGroupInitial: Group, mouseVector, checkGroup, lockedAxis) {
		let activeItem;
		let selectedGroup = selectedGroupInitial;
		//// Only take into accout left click
		if (evt.which != 1) return;
		this.highlightGroup(false, selectedGroup);

		const selectObject = this.getCollisionObject(
			mouseVector,
			checkGroup
		);
		//// Check selection
		if (
			selectObject &&
			selectObject.object.userData.type == 'ObjectGroup'
		) {
			this.controlsManager.controls.enableRotate = false;

			this.pIntersect.copy(selectObject.intersection.point);
			this.plane.setFromNormalAndCoplanarPoint(
				this.pNormal[lockedAxis],
				this.pIntersect
			);
			this.shift.subVectors(
				selectObject.object.position,
				selectObject.intersection.point
			);

			activeItem = selectObject.object as Group;
			selectedGroup = selectObject.object as Group;
			this.highlightGroup(true, selectedGroup);
			this.editorRepository.setSelectedItems([
				selectObject as unknown as GenericItem,
			]);

			this.editorRepository.setPartEditorSelectedItem(selectObject?.object?.userData?.itemId);
		} else {
			this.editorRepository.setSelectedItems([]);
			selectedGroup = null;
			this.editorRepository.setPartEditorSelectedItem(null);
		}

		this.sceneManager.needToRender();
		return {activeItem, selectedGroup};
	}


	checkCutout(object) {
		return object.userData.type == 'cutout';
	}

	selectCutout(isCutoutSelected: boolean, object?: Mesh) {
		this.controlsManager.controls.enableRotate = !isCutoutSelected;
		if (!object) return;
		this.activeCutout = object;
		this.pIntersect.copy(this.lastIntersection.point);
		this.plane.setFromNormalAndCoplanarPoint(
			this.pNormal[object.userData.lockedAxis],
			this.pIntersect
		);
		this.shift.subVectors(
			object.position,
			this.lastIntersection.point
		);
	}

	moveObject(planeIntersect, object) {
		//// Move alongside the locked plane axis
		this.sceneManager.raycaster.ray.intersectPlane(this.plane, planeIntersect);
		object.position.addVectors(planeIntersect, this.shift);
	}


	// This function is only used for the partEditor
	highlightGroup(isSelected: boolean, selectedObject: Group | null) {
		if (!selectedObject) {
			return;
		}

		for (const object of selectedObject.children) {
			this.highlightGroupObject(isSelected, object);
		}
	}

	highlightObjects(items: GenericItem[], isolatedItem: GenericItem, editorMode: EditorMode): void {
		//@ts-ignore
		const cleanedItems: GenericItem[] = items.reduce((acc, item) => {
			if (!item) {
				return acc;
			}

			return [...acc, item];
		}, []);

		//@ts-ignore
		if (cleanedItems.length == 0) {
			return;
		}

		//@ts-ignore
		cleanedItems.forEach(({ article, id, articleZone, mesh: originalMesh, type, faceNormal }) => {
			let mesh: Mesh;

			if (type === 'face' && faceNormal) {
				return (originalMesh as Mesh).material = bufferMaterialMap({
					...mesh?.userData?.object?.mesh || {},
					colour: '#AA8605',
				});
			}

			// Check if there is a panelId, we can just select that one then
			if (id) {
				mesh = this.findNameInGroups(id, this.sceneManager.scene.children);
			}

			//@ts-ignore
			if (article && !mesh) {
				mesh = this.findArticleInGroups(
					article,
					this.sceneManager.scene.children
				);
			}

			//@ts-ignore
			if (articleZone && !mesh) {
				mesh = this.findArticleZoneInGroups(
					articleZone,
					this.sceneManager.scene.children
				);
			}

			//@ts-ignore
			if (!mesh) {
				return;
			}

			const ignoreHighlightIds = [mesh.id, ...cleanedItems.reduce((acc, { id }) => {
				if (!id) {
					return acc;
				}

				const foundMesh = this.findNameInGroups(id,  this.sceneManager.scene.children);
				if (!foundMesh) {
					return acc;
				}

				return [...acc, foundMesh?.id]
			}, [])];

			this.highlightChildren(mesh.parent!.children, isolatedItem?.id, editorMode === EditorMode.DEFAULT ? undefined : EDITOR_STATE_COLOURS.ARTICLE_ZONE_SELECTION_CLICKABLE_ZONES, ignoreHighlightIds); // Apply "green highlight"
			this.applyHighlight(mesh, isolatedItem?.id, editorMode === EditorMode.DEFAULT ? '#AA8605' : EDITOR_STATE_COLOURS.ARTICLE_ZONE_SELECTION_SELECTED_ZONES); // Apply "yellow highlight"
		});
	}

	private highlightChildren = (children: Object3D[], isolatedItemId?: string, customColour?: string, skipIds: any[] = []): void => {
		children.forEach((child) => {
			if (skipIds.includes(child.id)) {
				return;
			}

			this.highlightChildren(child.children, isolatedItemId, customColour);
			this.applyHighlight(child, isolatedItemId, customColour)
		})
	}

	private applyHighlight = (child: Object3D, isolatedItemId?: string, customColour?: string): void => {
		const hasCustomisation = !!(child?.userData?.object?.customisations || []).length;
		const shouldBeIsolated = (isolatedItemId && isolatedItemId !== child?.userData?.object?.id);

		if (!child.userData.highlighted) {
			// If the material was not highlighted, we can assume the current material is the original non-highlighted one.
			child.userData.originalMaterial = (child as Mesh).material;
		}

		if (hasCustomisation) {
			child.userData.highlighted = true;
			(child as Mesh).material = customisedMaterialMap({
				...child?.userData?.object?.mesh || {},
				...(shouldBeIsolated && { opacity: 0.15, transparent: true }),
				colour: customColour || '#175832',
			});

			return;
		}

		child.userData.highlighted = true;
		(child as Mesh).material = bufferMaterialMap({
			...child?.userData?.object?.mesh,
			...(shouldBeIsolated && { opacity: 0.15, transparent: true }),
			colour: customColour || '#175832',
		});
	}

	public findIdInScene(name: string): Mesh {
		return this.findNameInGroups(name,  this.sceneManager.scene.children);
	}

	public findNameInGroups(name: string, children: any[]): Mesh {
		return children.reduce((acc, group) => {
			if (acc) {
				return acc;
			}

			if (group.name === name) {
				return group;
			}

			return this.findNameInGroups(name, group.children || []);
		}, null);
	}

	private findArticleInGroups(article: IArticle, children: any[]): Mesh {
		return children.reduce((acc, group) => {
			if (acc) {
				return acc;
			}

			if (group?.userData?.object?.article?.id === article.id) {
				return group;
			}

			return this.findArticleInGroups(article, group.children || []);
		}, null);
	}


	private findArticleZoneInGroups(articleZone: IArticleZone, children: any[]): Mesh {
		return children.reduce((acc, group) => {
			if (acc) {
				return acc;
			}

			if (group?.userData?.object?.articleZone?.id === articleZone.id) {
				return group;
			}

			return this.findArticleZoneInGroups(articleZone, group.children || []);
		}, null);
	}


	private highlightGroupObject(isSelected: boolean, object: Object3D): void {
		if (object?.children) {
			object.children.forEach((childGroup) => this.highlightGroupObject(isSelected, childGroup))
		}

		if (!object || !(object as Mesh).material) {
			return;
		}

		if (((object as Mesh).material as Material)?.type === "MeshStandardMaterial") {
			if (object.userData.highlighted && isSelected) {
				// If it was already highlighted and should be highlighted, do nothing
				return;
			}

			if (object.userData.highlighted && !isSelected) {
				// If it was already highlighted and should NOT be selected, restore the og texture
				object.userData.highlighted = false;
				(object as Mesh).material = object.userData.originalMaterial
				return;
			}

			object.userData.originalMaterial = (object as Mesh).material;
			object.userData.highlighted = true;

			(object as Mesh).material = bufferMaterialMap({
				...object?.userData?.object?.mesh,
				colour: '#175832',
			});
		}
	}
}
